import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

//gambar
import PrintIcon from '@mui/icons-material/Print';
import BorderAllIcon from '@mui/icons-material/BorderAll';

function Lap_Hutang_Piutang(props, ref) {
    //state    
    const [data_anggota, setDataAnggota] = useState([]);
    const [anggota, setAnggota] = useState(null);
    const [keanggotaan, setKeanggotaan] = useState("");

    //fungsi    
    useEffect(() => {
        fetch(props.aplikasi + '/karyawan/tampil_karyawan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataAnggota(data.data);
                }
                else {
                    setDataAnggota([]);
                }
            })
            .catch(error => {
                setDataAnggota([]);
            }
            );
    }, [props.aplikasi]);

    const cetak_pelanggan = () => {
        let id_karyawan = "";
        if (anggota !== null) {
            id_karyawan = anggota.id_karyawan;
        }

        window.open(props.aplikasi + '/laporan/cetak_hutang_pelanggan.php?permintaan=&id_karyawan=' + id_karyawan);
    }

    const excel_pelanggan = () => {
        let id_karyawan = "";
        if (anggota !== null) {
            id_karyawan = anggota.id_karyawan;
        }

        window.open(props.aplikasi + '/laporan/excel_hutang_pelanggan.php?permintaan=&id_karyawan=' + id_karyawan);
    }

    const cetak_pelanggan_lunas = () => {
        let id_karyawan = "";
        if (anggota !== null) {
            id_karyawan = anggota.id_karyawan;
        }

        window.open(props.aplikasi + '/laporan/cetak_hutang_pelanggan.php?permintaan=lunas&id_karyawan=' + id_karyawan);
    }

    const excel_pelanggan_lunas = () => {
        let id_karyawan = "";
        if (anggota !== null) {
            id_karyawan = anggota.id_karyawan;
        }

        window.open(props.aplikasi + '/laporan/excel_hutang_pelanggan.php?permintaan=lunas&id_karyawan=' + id_karyawan);
    }

    const cetak_pelanggan_keanggotaan = () => {
        window.open(props.aplikasi + '/laporan/cetak_hutang_pelanggan_keanggotaan.php?permintaan=&keanggotaan=' + keanggotaan);
    }

    const excel_pelanggan_keanggotaan = () => {
        window.open(props.aplikasi + '/laporan/excel_hutang_pelanggan_keanggotaan.php?permintaan=&keanggotaan=' + keanggotaan);
    }

    const cetak_pelanggan_lunas_keanggotaan = () => {
        window.open(props.aplikasi + '/laporan/cetak_hutang_pelanggan_keanggotaan.php?permintaan=lunas&keanggotaan=' + keanggotaan);
    }

    const excel_pelanggan_lunas_keanggotaan = () => {
        window.open(props.aplikasi + '/laporan/excel_hutang_pelanggan_keanggotaan.php?permintaan=lunas&keanggotaan=' + keanggotaan);
    }

    const cetak_supplier = () => {
        window.open(props.aplikasi + '/laporan/cetak_hutang_supplier.php?permintaan=');
    }

    const excel_supplier = () => {
        window.open(props.aplikasi + '/laporan/excel_hutang_supplier.php?permintaan=');
    }

    const cetak_supplier_lunas = () => {
        window.open(props.aplikasi + '/laporan/cetak_hutang_supplier.php?permintaan=lunas');
    }

    const excel_supplier_lunas = () => {
        window.open(props.aplikasi + '/laporan/excel_hutang_supplier.php?permintaan=lunas');
    }

    return (
        <div>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Typography variant="h6" noWrap={true}>Data Hutang Pelanggan</Typography>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Member
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Autocomplete
                                    options={data_anggota}
                                    getOptionLabel={
                                        (option) => option.nama + " (" + option.bagian + ")"
                                    }
                                    value={anggota}
                                    onChange={(e, nilai) => setAnggota(nilai)}
                                    renderInput={
                                        (params) =>
                                            <TextField
                                                {...params}
                                                key={params.id_karyawan}
                                                variant="outlined"
                                                size="small"
                                            />
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_pelanggan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak Hutang</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_pelanggan_lunas()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak Lunas</Typography>
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel_pelanggan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel Hutang</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel_pelanggan_lunas()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel Lunas</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Typography variant="h6" noWrap={true}>Data Hutang Pelanggan Berdasarkan Keanggotaan</Typography>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Keanggotaan
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={keanggotaan}
                                    onChange={(event) => setKeanggotaan(event.target.value)}
                                >
                                    <MenuItem value="">Seluruhnya</MenuItem>
                                    <MenuItem value="RS">Karyawan RS</MenuItem>
                                    <MenuItem value="TK">Karyawan TK</MenuItem>
                                    <MenuItem value="Lainnya">Lainnya</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_pelanggan_keanggotaan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak Hutang</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_pelanggan_lunas_keanggotaan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak Lunas</Typography>
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel_pelanggan_keanggotaan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel Hutang</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel_pelanggan_lunas_keanggotaan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel Lunas</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Typography variant="h6" noWrap={true}>Data Hutang Ke Supplier</Typography>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_supplier()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak Hutang</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_supplier_lunas()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak Lunas</Typography>
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel_supplier()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel Hutang</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel_supplier_lunas()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel Lunas</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default forwardRef(Lap_Hutang_Piutang);