// core
import React, { useState, useEffect, forwardRef } from 'react';

// 3rd
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// aset
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import CachedIcon from '@mui/icons-material/Cached';

function Pengguna(props, ref) {
    // state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_pengguna, setSumberPengguna] = useState([]);
    const [data_pengguna, setDataPengguna] = useState([]);
    const [id_pengguna, setIdPengguna] = useState("");
    const [nama, setNama] = useState("");
    const [alamat, setAlamat] = useState("");
    const [no_telpon, setNoTelpon] = useState("");
    const [nik, setNik] = useState("");
    const [username, setUsername] = useState("");
    const [data_gl, setDataGl] = useState([]);
    const [level, setLevel] = useState("S");
    const [id_gl, setIdGl] = useState("");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [tampil_dialog_reset, setTampilDialogReset] = useState(false);

    // fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pengguna/tampil_pengguna.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPengguna(data.data);
                    setDataPengguna(data.data);
                }
                else {
                    setSumberPengguna([]);
                    setDataPengguna([]);
                }
            })
            .catch(error => {
                setSumberPengguna([]);
                setDataPengguna([]);
            }
            );

        fetch(props.aplikasi + '/pengguna/tampil_gl.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataGl(data.data);
                }
                else {
                    setDataGl([]);
                }
            })
            .catch(error => {
                setDataGl([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_pengguna = () => {
        fetch(props.aplikasi + '/pengguna/tampil_pengguna.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberPengguna(data.data);
                    setDataPengguna(data.data);
                }
                else {
                    setSumberPengguna([]);
                    setDataPengguna([]);
                }
            })
            .catch(error => {
                setSumberPengguna([]);
                setDataPengguna([]);
            }
            );
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_pengguna.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_pengguna[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_pengguna[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_pengguna[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataPengguna(tampung);
    }

    const bersih_input_pengguna = () => {
        setIdPengguna("");
        setNama("");
        setAlamat("");
        setNoTelpon("");
        setNik("");
        setUsername("");
        setLevel("S");
        setIdGl("");
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const simpan = () => {
        if (nama === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (username.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Username wajib diisi");
            setTampilNotif(true);
            document.getElementById("username").focus();
            return;
        }

        if (level.trim() === "A" && id_gl.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("General Ledger wajib diisi");
            setTampilNotif(true);
            document.getElementById("gl").focus();
            return;
        }

        setProses(true);
        if (id_pengguna === "") {
            fetch(props.aplikasi + '/pengguna/simpan_pengguna.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_cabang: localStorage.getItem("id_cabang"),
                            id_pengguna: "",
                            nama: nama,
                            alamat: alamat,
                            no_telpon: no_telpon,
                            nik: nik,
                            username: username,                            
                            level: level,
                            id_gl: id_gl,
                            status: status,
                            pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_pengguna();
                        tampil_pengguna();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_pengguna();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_pengguna();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/pengguna/simpan_pengguna.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_cabang: localStorage.getItem("id_cabang"),
                            id_pengguna: id_pengguna,
                            nama: nama,
                            alamat: alamat,
                            no_telpon: no_telpon,
                            nik: nik,
                            username: username, 
                            level: level,                           
                            id_gl: id_gl,
                            status: status,
                            pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_pengguna();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_pengguna();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_pengguna();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
    }

    const reset_password = () => {
        setProses(true);
        fetch(props.aplikasi + '/pengguna/reset_password.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: id_pengguna,
                        pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_pengguna();
                    tampil_pengguna();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                else {
                    tampil_pengguna();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_pengguna();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("nama").focus();
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_pengguna();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama pengguna"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_pengguna.map((data_pengguna) => {
                        return (
                            <Grid
                                item xs={12} sm={6} md={4} lg={3}
                                key={data_pengguna.id_pengguna}
                                onClick={() => {
                                    bersih_input_pengguna();
                                    setIdPengguna(data_pengguna.id_pengguna);
                                    setNama(data_pengguna.nama);
                                    setAlamat(data_pengguna.alamat);
                                    setNoTelpon(data_pengguna.no_telpon);
                                    setNik(data_pengguna.nik);
                                    setUsername(data_pengguna.username);
                                    if (data_pengguna.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }

                                    setLevel(data_pengguna.level);
                                    setIdGl(data_pengguna.id_gl);
                                    setTampilDialog(true);
                                }}
                            >
                                <Card
                                    sx={
                                        data_pengguna.status === "0" ?
                                            {
                                                backgroundColor: "#FA7070",
                                                color: "white"
                                            }
                                            :
                                            {}
                                    }
                                >
                                    <List>
                                        <ListItem button alignItems="flex-start">
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" noWrap>{data_pengguna.nama}</Typography>
                                                }
                                                secondary={
                                                    <Typography variant="subtitle1" noWrap>{data_pengguna.username}</Typography>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Pengguna</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Nama Lengkap
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Alamat
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="alamat"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={alamat}
                                        onChange={(event) => setAlamat(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        No. Telpon
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="no-telpon"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={no_telpon}
                                        onChange={(event) => setNoTelpon(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        NIK
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="nik"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={nik}
                                        onChange={(event) => setNik(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Username
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="username"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={username}
                                        onChange={(event) => setUsername(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Level Jabatan
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControl size="small" fullWidth>
                                        <Select
                                            id="level"
                                            variant="outlined"
                                            fullWidth
                                            value={level}
                                            onChange={(event) => {
                                                setLevel(event.target.value);
                                                if (event.target.value === "S"){
                                                    setIdGl("");
                                                }
                                            }}
                                        >
                                            <MenuItem value="S">Sales</MenuItem>
                                            <MenuItem value="A">Admin</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item
                                style={
                                    level === "A" ? {} : { display: "none" }
                                }
                            >
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        General Ledger
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControl size="small" fullWidth>
                                        <Select
                                            id="gl"
                                            variant="outlined"
                                            fullWidth
                                            value={id_gl}
                                            onChange={(event) => setIdGl(event.target.value)}
                                        >
                                            {data_gl.map((data_gl) => {
                                                return (
                                                    <MenuItem key={data_gl.id_gl} value={data_gl.id_gl}>{data_gl.gl}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {/* <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Menu
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControl sx={{ m: 1, width: 300 }}>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={menu}
                                            onChange={(event) => {
                                                const {
                                                    target: { value },
                                                } = event;
                                                setMenu(
                                                    typeof value === 'string' ? value.split(',') : value,
                                                );
                                            }}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {data_menu.map((tampung_data_menu, index) => (
                                                <MenuItem key={index} value={index}>
                                                    <Checkbox checked={menu.indexOf(index) > -1} />
                                                    <ListItemText primary={tampung_data_menu.menu_nama + " - " + tampung_data_menu.nama} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid> */}

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography component={"span"} variant="body2" noWrap={true}>{id_pengguna === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}
                        sx={{ borderRadius: "5em", backgroundColor: "tomato" }}
                        loading={proses}
                        onClick={() => setTampilDialogReset(true)}
                    >
                        <Typography component={"span"} variant="body2" noWrap={true}>Reset Password</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography component={"span"} variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_reset}
                onClose={() => setTampilDialogReset(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Reset Password
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin akan mereset password pengguna menjadi sama dengan nama akun?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={proses}
                        onClick={() => setTampilDialogReset(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loading={proses}
                        onClick={() => {
                            setTampilDialogReset(false);
                            setTampilDialog(false);
                            reset_password();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Pengguna);