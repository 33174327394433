// core
import React, { useState, useEffect, forwardRef } from 'react';

// 3rd
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// aset
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SaveOutlined from '@mui/icons-material/SaveOutlined';

function Pengaturan(props, ref) {
    // state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [nama, setNama] = useState("");
    const [username, setUsername] = useState("");
    const [ubah_password, setUbahPassword] = useState(false);
    const [label_ubah_password, setLabelUbahPassword] = useState("Tidak");
    const [password_lama, setPasswordLama] = useState("");
    const [tampil_password_lama, setTampilPasswordLama] = useState(false);
    const [password_baru, setPasswordBaru] = useState("");
    const [tampil_password_baru, setTampilPasswordBaru] = useState(false);
    const [password_konfirmasi, setPasswordKonfirmasi] = useState("");
    const [tampil_password_konfirmasi, setTampilPasswordKonfirmasi] = useState(false);

    // fungsi
    useEffect(() => {
        setNama(localStorage.getItem("nama"));
        setUsername(localStorage.getItem("username"));
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const simpan = () => {
        if (ubah_password === true) {
            if (password_lama === "") {
                setJenisNotif("warning");
                setIsiNotif("Password lama wajib diisi");
                setTampilNotif(true);
                document.getElementById("password_lama").focus();
                return;
            }

            if (password_baru === "") {
                setJenisNotif("warning");
                setIsiNotif("Password baru wajib diisi");
                setTampilNotif(true);
                document.getElementById("password_baru").focus();
                return;
            }

            if (password_konfirmasi === "") {
                setJenisNotif("warning");
                setIsiNotif("Konfirmasi password baru wajib diisi");
                setTampilNotif(true);
                document.getElementById("password_konfirmasi").focus();
                return;
            }

            if (password_baru !== password_konfirmasi) {
                setJenisNotif("warning");
                setIsiNotif("Password baru tidak sama dengan konfirmasi");
                setTampilNotif(true);
                document.getElementById("password_baru").focus();
                return;
            }
        }

        setProses(true);
        fetch(props.aplikasi + '/pengaturan/simpan_pengaturan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        username: username,
                        nama: nama,
                        ubah_password: ubah_password,
                        password_lama: password_lama,
                        password_baru: password_baru,
                        password_konfirmasi: password_konfirmasi
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    localStorage.setItem("nama", nama);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("nama").focus();
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container item spacing={1}>
                        <Grid container item>
                            <Grid item xs={12} sm={4} md={3}>
                                <Typography variant="body2">
                                    Username
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={9}>
                                <TextField
                                    id="username"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "white"
                                    }}
                                    value={username}
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={12} sm={4} md={3}>
                                <Typography variant="body2">
                                    Nama Lengkap
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={9}>
                                <TextField
                                    id="nama"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "white"
                                    }}
                                    value={nama}
                                    onChange={(event) => setNama(event.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={12} sm={4} md={3}>
                                <Typography variant="body2">
                                    Ubah Password
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={9}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={ubah_password}
                                            onChange={(event) => {
                                                if (ubah_password === false) {
                                                    setUbahPassword(true);
                                                    setLabelUbahPassword("Ya");
                                                    setPasswordLama("");
                                                    setPasswordBaru("");
                                                    setPasswordKonfirmasi("");
                                                }
                                                else {
                                                    setUbahPassword(false);
                                                    setLabelUbahPassword("Tidak");
                                                }
                                            }}
                                        />
                                    }

                                    label={label_ubah_password}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item
                            sx={ubah_password === true ? {} : { display: "none" }}
                        >
                            <Grid item xs={12} sm={4} md={3}>
                                <Typography variant="body2">
                                    Password Lama
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={9}>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                    <OutlinedInput
                                        id="password_lama"
                                        size="small"
                                        fullWidth
                                        type={tampil_password_lama ? "text" : "password"}
                                        sx={{
                                            backgroundColor: "white"
                                        }}
                                        value={password_lama}
                                        onChange={(event) => setPasswordLama(event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={(event) => setTampilPasswordLama(!tampil_password_lama)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                    edge="end"
                                                >
                                                    {tampil_password_lama ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item
                            sx={ubah_password === true ? {} : { display: "none" }}
                        >
                            <Grid item xs={12} sm={4} md={3}>
                                <Typography variant="body2">
                                    Password Baru
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={9}>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                    <OutlinedInput
                                        id="password_baru"
                                        size="small"
                                        fullWidth
                                        type={tampil_password_baru ? "text" : "password"}
                                        sx={{
                                            backgroundColor: "white"
                                        }}
                                        value={password_baru}
                                        onChange={(event) => setPasswordBaru(event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={(event) => setTampilPasswordBaru(!tampil_password_baru)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                    edge="end"
                                                >
                                                    {tampil_password_baru ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item
                            sx={ubah_password === true ? {} : { display: "none" }}
                        >
                            <Grid item xs={12} sm={4} md={3}>
                                <Typography variant="body2">
                                    Konfirmasi Password Baru
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={9}>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                    <OutlinedInput
                                        id="password_konfirmasi"
                                        size="small"
                                        fullWidth
                                        type={tampil_password_konfirmasi ? "text" : "password"}
                                        sx={{
                                            backgroundColor: "white"
                                        }}
                                        value={password_konfirmasi}
                                        onChange={(event) => setPasswordKonfirmasi(event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={(event) => setTampilPasswordKonfirmasi(!tampil_password_konfirmasi)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                    edge="end"
                                                >
                                                    {tampil_password_konfirmasi ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={12} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={12} sm={8} md={9}>
                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveOutlined />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                    loading={proses}
                                    onClick={() => simpan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Simpan</Typography>
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default forwardRef(Pengaturan);