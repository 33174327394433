//utama
import * as React from 'react';
import { Navigate } from 'react-router-dom';

//form
import UtamaAdmin from './Admin/utama';

function Utama(props) {
    //fungsi    
    const keluar = () => {
        localStorage.clear();
    }

    if (!localStorage.getItem("login")) {
        return <Navigate to="/login" />;
    }
    else {
        return (
            <UtamaAdmin
                aplikasi={props.aplikasi}
                file={props.file}
                basename={props.basename}
                proses_keluar={() => keluar()}
            />
        )
    }
}

export default Utama;