// core
import React, { useState, useEffect, forwardRef } from 'react';

// 3rd
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import ButtonGroup from '@mui/material/ButtonGroup';

// aset
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import PrintIcon from '@mui/icons-material/Print';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CachedIcon from '@mui/icons-material/Cached';
import SaveIcon from '@mui/icons-material/Save';
import ListIcon from '@mui/icons-material/List';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Penjualan(props, ref) {
    // state
    const [proses, setProses] = useState(false);
    const [tampil_blokir, setTampilBlokir] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [kode, setKode] = useState("");
    const [pencarian, setPencarian] = useState("");
    const [data_produk, setDataProduk] = useState([]);
    const [jumlah_baris_produk] = useState(10);
    const [jumlah_halaman_produk, setJumlahHalamanProduk] = useState(0);
    const [halaman_produk, setHalamanProduk] = useState(0);
    const [id_draft, setIdDraft] = useState("");
    const [id_draft_hapus, setIdDraftHapus] = useState("");
    const [data_draft, setDataDraft] = useState([]);
    const [jumlah_baris_draft] = useState(10);
    const [jumlah_halaman_draft, setJumlahHalamanDraft] = useState(0);
    const [halaman_draft, setHalamanDraft] = useState(0);
    const [data_belanja, setDataBelanja] = useState([]);
    const [data_metode_bayar, setDataMetodeBayar] = useState([]);
    const [jumlah_metode_bayar, setJumlahMetodeBayar] = useState(1);
    const [metode_bayar, setMetodeBayar] = useState("1");
    const [metode_bayar2, setMetodeBayar2] = useState("");
    const [jumlah_bayar, setJumlahBayar] = useState(0);
    const [jumlah_bayar2, setJumlahBayar2] = useState(0);
    const [status_sales, setStatusSales] = useState(false);
    const [label_status_sales, setLabelStatusSales] = useState("Tidak ada");
    const [status_pembeli, setStatusPembeli] = useState(false);
    const [label_status_pembeli, setLabelStatusPembeli] = useState("Umum");
    const [data_sales, setDataSales] = useState([]);
    const [data_pelanggan, setDataPelanggan] = useState([]);
    const [sales, setSales] = useState(null);
    const [mobil, setMobil] = useState("");
    const [pelanggan, setPelanggan] = useState(null);
    const [metode_diskon, setMetodeDiskon] = useState("nominal");
    const [diskon_persen, setDiskonPersen] = useState(0);
    const [diskon, setDiskon] = useState(0);
    const [id_penjualan, setIdPenjualan] = useState("");

    const [tambah_id_produk, setTambahIdProduk] = useState("");
    const [tambah_nama, setTambahNama] = useState("");
    const [tambah_harga_jual1, setTambahHargaJual1] = useState(0);
    const [tambah_harga_jual_member1, setTambahHargaJualMember1] = useState(0);
    const [tambah_stok1, setTambahStok1] = useState(0);
    const [tambah_satuan1, setTambahSatuan1] = useState("");
    const [tambah_id_satuan2, setTambahIdSatuan2] = useState("");
    const [tambah_harga_jual2, setTambahHargaJual2] = useState(0);
    const [tambah_harga_jual_member2, setTambahHargaJualMember2] = useState(0);
    const [tambah_stok2, setTambahStok2] = useState(0);
    const [tambah_satuan2, setTambahSatuan2] = useState("");
    const [tambah_pecahan2, setTambahPecahan2] = useState(0);
    const [tambah_id_satuan3, setTambahIdSatuan3] = useState("");
    const [tambah_harga_jual3, setTambahHargaJual3] = useState(0);
    const [tambah_harga_jual_member3, setTambahHargaJualMember3] = useState(0);
    const [tambah_stok3, setTambahStok3] = useState(0);
    const [tambah_satuan3, setTambahSatuan3] = useState("");
    const [tambah_pecahan3, setTambahPecahan3] = useState(0);
    const [tambah_id_satuan4, setTambahidSatuan4] = useState("");
    const [tambah_harga_jual4, setTambahHargaJual4] = useState(0);
    const [tambah_harga_jual_member4, setTambahHargaJualMember4] = useState(0);
    const [tambah_stok4, setTambahStok4] = useState(0);
    const [tambah_satuan4, setTambahSatuan4] = useState("");
    const [tambah_pecahan4, setTambahPecahan4] = useState(0);
    const [tambah_jumlah1, setTambahJumlah1] = useState(0);
    const [tambah_jumlah2, setTambahJumlah2] = useState(0);
    const [tambah_jumlah3, setTambahJumlah3] = useState(0);
    const [tambah_jumlah4, setTambahJumlah4] = useState(0);

    const [tampil_dialog_cari_produk, setTampilDialogCariProduk] = useState(false);
    const [tampil_dialog_harga, setTampilDialogHarga] = useState(false);
    const [tampil_dialog_diskon_member, setTampilDialogDiskonMember] = useState(false);
    const [tampil_dialog_tambah, setTampilDialogTambah] = useState(false);
    const [tampil_dialog_diskon, setTampilDialogDiskon] = useState(false);
    const [tampil_dialog_draft, setTampilDialogDraft] = useState(false);
    const [tampil_dialog_hapus_draft, setTampilDialogHapusDraft] = useState(false);
    const [tampil_dialog_batal, setTampilDialogBatal] = useState(false);
    const [tampil_dialog_bayar, setTampilDialogBayar] = useState(false);

    // fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/penjualan/tampil_metode_bayar.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                    setTampilBlokir(true);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
                setTampilBlokir(true);
            }
            );

        fetch(props.aplikasi + '/penjualan/tampil_sales.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataSales(data.data);
                }
                else {
                    setDataSales([]);
                }
            })
            .catch(error => {
                setDataSales([]);
                setTampilBlokir(true);
            }
            );

        fetch(props.aplikasi + '/penjualan/tampil_pelanggan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPelanggan(data.data);
                }
                else {
                    setDataPelanggan([]);
                }
            })
            .catch(error => {
                setDataPelanggan([]);
                setTampilBlokir(true);
            }
            );
    }, [props.aplikasi]);

    useEffect(() => {
        let tabel_belanja = document.getElementById("tabel-belanja");
        tabel_belanja.scrollTop = tabel_belanja.scrollHeight;
    }, [data_belanja]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const cari = () => {
        fetch(props.aplikasi + '/penjualan/tampil_cari_produk.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        kode: kode
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    let cari_id_jenis = data.data[0]["id_jenis"];
                    let cari_jenis = data.data[0]["jenis"];
                    let cari_id_produk = data.data[0]["id_produk"];
                    let cari_nama = data.data[0]["nama"];
                    let cari_id_satuan1 = data.data[0]["id_satuan1"];
                    let cari_harga_beli1 = data.data[0]["harga_beli1"];
                    let cari_harga_jual1 = data.data[0]["harga_jual1"];
                    let cari_harga_jual_member1 = data.data[0]["harga_jual_member1"];
                    let cari_satuan1 = data.data[0]["satuan1"];
                    let cari_id_satuan2 = data.data[0]["id_satuan2"];
                    let cari_harga_beli2 = data.data[0]["harga_beli2"];
                    let cari_harga_jual2 = data.data[0]["harga_jual2"];
                    let cari_harga_jual_member2 = data.data[0]["harga_jual_member2"];
                    let cari_satuan2 = data.data[0]["satuan2"];
                    let cari_pecahan2 = data.data[0]["pecahan2"];
                    let cari_id_satuan3 = data.data[0]["id_satuan3"];
                    let cari_harga_beli3 = data.data[0]["harga_beli3"];
                    let cari_harga_jual3 = data.data[0]["harga_jual3"];
                    let cari_harga_jual_member3 = data.data[0]["harga_jual_member3"];
                    let cari_satuan3 = data.data[0]["satuan"];
                    let cari_pecahan3 = data.data[0]["pecahan3"];
                    let cari_id_satuan4 = data.data[0]["id_satuan4"];
                    let cari_harga_beli4 = data.data[0]["harga_beli4"];
                    let cari_harga_jual4 = data.data[0]["harga_jual4"];
                    let cari_harga_jual_member4 = data.data[0]["harga_jual_member4"];
                    let cari_satuan4 = data.data[0]["satuan4"];
                    let cari_pecahan4 = data.data[0]["pecahan4"];

                    let cari_stok4 = 0;
                    let cari_stok3 = 0;
                    let cari_stok2 = 0;
                    let cari_stok1 = 0;
                    if (parseFloat(data.data[0]["jumlah4"]) > 0) {
                        cari_stok4 = parseFloat(data.data[0]["jumlah4"]) % parseFloat(cari_pecahan4);
                        cari_stok3 = parseFloat(data.data[0]["jumlah3"]) + (Math.floor(parseFloat(data.data[0]["jumlah4"]) / parseFloat(cari_pecahan4)));
                    }
                    else {
                        cari_stok3 = parseFloat(data.data[0]["jumlah3"]);
                    }

                    if (cari_stok3 > 0) {
                        cari_stok3 = cari_stok3 % parseFloat(cari_pecahan3);
                        cari_stok2 = parseFloat(data.data[0]["jumlah2"]) + (Math.floor(parseFloat(data.data[0]["jumlah3"]) / parseFloat(cari_pecahan3)));
                    }
                    else {
                        cari_stok2 = parseFloat(data.data[0]["jumlah2"]);
                    }

                    if (cari_stok2 > 0) {
                        cari_stok2 = cari_stok2 % parseFloat(cari_pecahan2);
                        cari_stok1 = parseFloat(data.data[0]["jumlah1"]) + (Math.floor(parseFloat(data.data[0]["jumlah2"]) / parseFloat(cari_pecahan2)));
                    }
                    else {
                        cari_stok1 = parseFloat(data.data[0]["jumlah1"]);
                    }

                    if (cari_stok1 <= 0 && cari_stok2 <= 0 && cari_stok3 <= 0 && cari_stok4 <= 0) {
                        setJenisNotif("warning");
                        setIsiNotif("Stok habis");
                        setTampilNotif(true);
                        return;
                    }

                    let tampung = [...data_belanja];
                    let awal = 1;
                    let akhir = tampung.length;
                    if (akhir > 0) {
                        while (awal <= akhir) {
                            if (cari_id_produk === tampung[awal - 1]["id_produk"]) {
                                let vartotalstok = parseFloat(data.data[0]["jumlah1"]);

                                if (parseFloat(cari_pecahan2) > 0) {
                                    vartotalstok = vartotalstok + (parseFloat(data.data[0]["jumlah2"]) / parseFloat(cari_pecahan2));
                                }

                                if (parseFloat(cari_pecahan3) > 0) {
                                    vartotalstok = vartotalstok + (parseFloat(data.data[0]["jumlah3"]) / parseFloat(cari_pecahan3) / parseFloat(cari_pecahan2));
                                }

                                if (parseFloat(cari_pecahan4) > 0) {
                                    vartotalstok = vartotalstok + (parseFloat(data.data[0]["jumlah4"]) / parseFloat(cari_pecahan4) / parseFloat(cari_pecahan3) / parseFloat(cari_pecahan2));
                                }

                                let cari_jumlah1 = parseFloat(tampung[awal - 1]["jumlah1"]);
                                let cari_jumlah2 = parseFloat(tampung[awal - 1]["jumlah2"]);
                                let cari_jumlah3 = parseFloat(tampung[awal - 1]["jumlah3"]);
                                let cari_jumlah4 = parseFloat(tampung[awal - 1]["jumlah4"]);

                                if (cari_jumlah1 > 0) {
                                    cari_jumlah1 = cari_jumlah1 + 1;
                                }
                                else if (cari_jumlah2 > 0) {
                                    cari_jumlah2 = cari_jumlah2 + 1;
                                }
                                else if (cari_jumlah3 > 0) {
                                    cari_jumlah3 = cari_jumlah3 + 1;
                                }
                                else if (cari_jumlah4 > 0) {
                                    cari_jumlah4 = cari_jumlah4 + 1;
                                }

                                let vartotaltambah = parseFloat(cari_jumlah1);
                                if (parseFloat(cari_pecahan2) > 0) {
                                    vartotaltambah = vartotaltambah + (parseFloat(cari_jumlah2) / parseFloat(cari_pecahan2));
                                }

                                if (parseFloat(tambah_pecahan3) > 0) {
                                    vartotaltambah = vartotaltambah + (parseFloat(cari_jumlah3) / parseFloat(cari_pecahan3) / parseFloat(cari_pecahan2));
                                }

                                if (parseFloat(tambah_pecahan4) > 0) {
                                    vartotaltambah = vartotaltambah + (parseFloat(cari_jumlah4) / parseFloat(cari_pecahan4) / parseFloat(cari_pecahan3) / parseFloat(cari_pecahan2));
                                }

                                if (vartotaltambah > vartotalstok) {
                                    setJenisNotif("warning");
                                    setIsiNotif("Jumlah melebihi stok");
                                    setTampilNotif(true);
                                    return;
                                }

                                tampung[awal - 1]["jumlah1"] = cari_jumlah1;
                                tampung[awal - 1]["jumlah2"] = cari_jumlah2;
                                tampung[awal - 1]["jumlah3"] = cari_jumlah3;
                                tampung[awal - 1]["jumlah4"] = cari_jumlah4;

                                setDataBelanja(tampung);
                                setKode("");
                                return;
                            }
                            awal++;
                        }

                        let cari_jumlah1 = 0;
                        let cari_jumlah2 = 0;
                        let cari_jumlah3 = 0;
                        let cari_jumlah4 = 0;

                        if (cari_stok1 > 0) {
                            if (cari_stok1 >= 1) {
                                cari_jumlah1 = 1;
                            }
                            else {
                                cari_jumlah1 = cari_stok1;
                            }
                        }
                        else if (cari_stok2 > 0) {
                            if (cari_stok2 >= 1) {
                                cari_jumlah2 = 1;
                            }
                            else {
                                cari_jumlah2 = cari_stok2;
                            }
                        }
                        else if (cari_stok3 > 0) {
                            if (cari_stok3 >= 1) {
                                cari_jumlah3 = 1;
                            }
                            else {
                                cari_jumlah3 = cari_stok3;
                            }
                        }
                        else if (cari_stok4 > 0) {
                            if (cari_stok4 >= 1) {
                                cari_jumlah4 = 1;
                            }
                            else {
                                cari_jumlah4 = cari_stok4;
                            }
                        }

                        tampung.push({
                            "id_jenis": cari_id_jenis,
                            "jenis": cari_jenis,
                            "id_produk": cari_id_produk,
                            "nama": cari_nama,
                            "harga_beli1": cari_harga_beli1,
                            "harga_jual1": cari_harga_jual1,
                            "harga_jual_member1": cari_harga_jual_member1,
                            "id_satuan1": cari_id_satuan1,
                            "satuan1": cari_satuan1,
                            "stok1": cari_stok1,
                            "jumlah1": cari_jumlah1,
                            "id_satuan2": cari_id_satuan2,
                            "harga_beli2": cari_harga_beli2,
                            "harga_jual2": cari_harga_jual2,
                            "harga_jual_member2": cari_harga_jual_member2,
                            "satuan2": cari_satuan2,
                            "stok2": cari_stok2,
                            "jumlah2": cari_jumlah2,
                            "pecahan2": cari_pecahan2,
                            "id_satuan3": cari_id_satuan3,
                            "harga_beli3": cari_harga_beli3,
                            "harga_jual3": cari_harga_jual3,
                            "harga_jual_member3": cari_harga_jual_member3,
                            "satuan3": cari_satuan3,
                            "stok3": cari_stok3,
                            "jumlah3": cari_jumlah3,
                            "pecahan3": cari_pecahan3,
                            "id_satuan4": cari_id_satuan4,
                            "harga_beli4": cari_harga_beli4,
                            "harga_jual4": cari_harga_jual4,
                            "harga_jual_member4": cari_harga_jual_member4,
                            "satuan4": cari_satuan4,
                            "stok4": cari_stok4,
                            "jumlah4": cari_jumlah4,
                            "pecahan4": cari_pecahan4
                        });
                    }
                    else {
                        let cari_jumlah1 = 0;
                        let cari_jumlah2 = 0;
                        let cari_jumlah3 = 0;
                        let cari_jumlah4 = 0;

                        if (cari_stok1 > 0) {
                            if (cari_stok1 >= 1) {
                                cari_jumlah1 = 1;
                            }
                            else {
                                cari_jumlah1 = cari_stok1;
                            }
                        }
                        else if (cari_stok2 > 0) {
                            if (cari_stok2 >= 1) {
                                cari_jumlah2 = 1;
                            }
                            else {
                                cari_jumlah2 = cari_stok2;
                            }
                        }
                        else if (cari_stok3 > 0) {
                            if (cari_stok3 >= 1) {
                                cari_jumlah3 = 1;
                            }
                            else {
                                cari_jumlah3 = cari_stok3;
                            }
                        }
                        else if (cari_stok4 > 0) {
                            if (cari_stok4 >= 1) {
                                cari_jumlah4 = 1;
                            }
                            else {
                                cari_jumlah4 = cari_stok4;
                            }
                        }

                        tampung.push({
                            "id_jenis": cari_id_jenis,
                            "jenis": cari_jenis,
                            "id_produk": cari_id_produk,
                            "nama": cari_nama,
                            "id_satuan1": cari_id_satuan1,
                            "harga_beli1": cari_harga_beli1,
                            "harga_jual1": cari_harga_jual1,
                            "harga_jual_member1": cari_harga_jual_member1,
                            "satuan1": cari_satuan1,
                            "stok1": cari_stok1,
                            "jumlah1": cari_jumlah1,
                            "id_satuan2": cari_id_satuan2,
                            "harga_beli2": cari_harga_beli2,
                            "harga_jual2": cari_harga_jual2,
                            "harga_jual_member2": cari_harga_jual_member2,
                            "satuan2": cari_satuan2,
                            "stok2": cari_stok2,
                            "jumlah2": cari_jumlah2,
                            "pecahan2": cari_pecahan2,
                            "id_satuan3": cari_id_satuan3,
                            "harga_beli3": cari_harga_beli3,
                            "harga_jual3": cari_harga_jual3,
                            "harga_jual_member3": cari_harga_jual_member3,
                            "satuan3": cari_satuan3,
                            "stok3": cari_stok3,
                            "jumlah3": cari_jumlah3,
                            "pecahan3": cari_pecahan3,
                            "id_satuan4": cari_id_satuan4,
                            "harga_beli4": cari_harga_beli4,
                            "harga_jual4": cari_harga_jual4,
                            "harga_jual_member4": cari_harga_jual_member4,
                            "satuan4": cari_satuan4,
                            "stok4": cari_stok4,
                            "jumlah4": cari_jumlah4,
                            "pecahan4": cari_pecahan4
                        });
                    }

                    let total = 0;
                    awal = 1;
                    while (awal <= akhir) {
                        if (status_pembeli === false) {
                            total = total +
                                (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual1"])) +
                                (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual2"])) +
                                (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual3"])) +
                                (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual4"]))
                                ;
                        }
                        else {
                            total = total +
                                (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual_member1"])) +
                                (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual_member2"])) +
                                (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual_member3"])) +
                                (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual_member4"]))
                                ;
                        }

                        awal++;
                    }

                    let tampung_diskon = (parseFloat(diskon_persen) / 100) * total;
                    setDiskon(tampung_diskon);

                    setDataBelanja(tampung);
                    setKode("");
                }
                else {
                    setKode("");
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setKode("");
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    const tampil_produk = (halaman_baru) => {
        fetch(props.aplikasi + '/penjualan/tampil_produk_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        status: "1",
                        cari: pencarian,
                        jumlah_baris: jumlah_baris_produk,
                        halaman: halaman_baru
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataProduk(data.data);
                    setJumlahHalamanProduk(Math.ceil(data.jumlah_data / jumlah_baris_produk));
                }
                else {
                    setDataProduk([]);
                    setJumlahHalamanProduk(0);
                }
            })
            .catch(error => {
                setDataProduk([]);
                setJumlahHalamanProduk(0);
            }
            );
    }

    const cari_produk = (keyword) => {
        setHalamanProduk(1);
        tampil_produk(1);
    }

    const pindah_halaman_produk = (halaman_produk_baru) => {
        setHalamanProduk(halaman_produk_baru);
        tampil_produk(halaman_produk_baru);
    }

    const tampil_konversi_stok = (varstok1, varsatuan1, varstok2, varsatuan2, varpecahan2, varstok3, varsatuan3, varpecahan3, varstok4, varsatuan4, varpecahan4) => {
        let stok4 = 0;
        let stok3 = 0;
        let stok2 = 0;
        let stok1 = 0;

        if (parseFloat(varstok4) > 0) {
            stok4 = parseFloat(varstok4) % parseFloat(varpecahan4);
            stok3 = parseFloat(varstok3) + (Math.floor(parseFloat(varstok4) / parseFloat(varpecahan4)));
        }
        else {
            stok3 = parseFloat(varstok3);
        }

        if (parseFloat(stok3) > 0) {
            stok3 = parseFloat(stok3) % parseFloat(varpecahan3);
            stok2 = parseFloat(varstok2) + (Math.floor(parseFloat(varstok3) / parseFloat(varpecahan3)));
        }
        else {
            stok2 = parseFloat(varstok2);
        }

        if (parseFloat(stok2) > 0) {
            stok2 = parseFloat(stok2) % parseFloat(varpecahan2);
            stok1 = parseFloat(varstok1) + (Math.floor(parseFloat(varstok2) / parseFloat(varpecahan2)));
        }
        else {
            stok1 = parseFloat(varstok1);
        }

        let varhasil = "";
        if (parseFloat(stok1) > 0) {
            varhasil = stok1 + " " + varsatuan1 + ", ";
        }

        if (parseFloat(stok2) > 0) {
            varhasil = varhasil + stok2 + " " + varsatuan2 + ", ";
        }

        if (parseFloat(stok3) > 0) {
            varhasil = varhasil + stok3 + " " + varsatuan3 + ", ";
        }

        if (parseFloat(stok4) > 0) {
            varhasil = varhasil + stok4 + " " + varsatuan4 + ", ";
        }

        varhasil = varhasil.substring(0, varhasil.length - 2);

        return varhasil;
    }

    const tampil_jumlah = (varjumlah1, varsatuan1, varjumlah2, varsatuan2, varjumlah3, varsatuan3, varjumlah4, varsatuan4) => {
        let varhasil = "";
        if (varjumlah1 > 0) {
            varhasil = format_rupiah(parseFloat(varjumlah1)) + " " + varsatuan1 + ", ";
        }

        if (varjumlah2 > 0) {
            varhasil = varhasil + format_rupiah(parseFloat(varjumlah2)) + " " + varsatuan2 + ", ";
        }

        if (varjumlah3 > 0) {
            varhasil = varhasil + format_rupiah(parseFloat(varjumlah3)) + " " + varsatuan3 + ", ";
        }

        if (varjumlah4 > 0) {
            varhasil = varhasil + format_rupiah(parseFloat(varjumlah4)) + " " + varsatuan4 + ", ";
        }

        return varhasil.substring(0, varhasil.length - 2);
    }

    const tampil_subtotal = (varjumlah1, varjumlah2, varjumlah3, varjumlah4, varharga_jual1, varharga_jual_member1, varharga_jual2, varharga_jual_member2, varharga_jual3, varharga_jual_member3, varharga_jual4, varharga_jual_member4) => {
        let varsubtotal = 0;

        if (status_pembeli === false) {
            varsubtotal = (varjumlah1 * varharga_jual1) + (varjumlah2 * varharga_jual2) + (varjumlah3 * varharga_jual3) + (varjumlah4 * varharga_jual4);
        }
        else {
            varsubtotal = (varjumlah1 * varharga_jual_member1) + (varjumlah2 * varharga_jual_member2) + (varjumlah3 * varharga_jual_member3) + (varjumlah4 * varharga_jual_member4);
        }

        return varsubtotal;
    }

    const ubah_jumlah = (varjumlah1, varjumlah2, varjumlah3, varjumlah4) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(varjumlah1) === false && varjumlah1 !== "") {
            return;
        }

        if (re.test(varjumlah2) === false && varjumlah2 !== "") {
            return;
        }

        if (re.test(varjumlah3) === false && varjumlah3 !== "") {
            return;
        }

        if (re.test(varjumlah4) === false && varjumlah4 !== "") {
            return;
        }

        if (varjumlah1 === "") {
            setTambahJumlah1(varjumlah1);
            return;
        }

        if (varjumlah2 === "") {
            setTambahJumlah2(varjumlah2);
            return;
        }

        if (varjumlah3 === "") {
            setTambahJumlah3(varjumlah3);
            return;
        }

        if (varjumlah4 === "") {
            setTambahJumlah4(varjumlah4);
            return;
        }

        let vartotalstok = parseFloat(tambah_stok1);

        if (parseFloat(tambah_pecahan2) > 0) {
            vartotalstok = vartotalstok + (parseFloat(tambah_stok2) / parseFloat(tambah_pecahan2));
        }

        if (parseFloat(tambah_pecahan3) > 0) {
            vartotalstok = vartotalstok + (parseFloat(tambah_stok3) / parseFloat(tambah_pecahan3) / parseFloat(tambah_pecahan2));
        }

        if (parseFloat(tambah_pecahan4) > 0) {
            vartotalstok = vartotalstok + (parseFloat(tambah_stok4) / parseFloat(tambah_pecahan4) / parseFloat(tambah_pecahan3) / parseFloat(tambah_pecahan2));
        }

        let vartotaltambah = parseFloat(varjumlah1);
        if (parseFloat(tambah_pecahan2) > 0) {
            vartotaltambah = vartotaltambah + (parseFloat(varjumlah2) / parseFloat(tambah_pecahan2));
        }

        if (parseFloat(tambah_pecahan3) > 0) {
            vartotaltambah = vartotaltambah + (parseFloat(varjumlah3) / parseFloat(tambah_pecahan3) / parseFloat(tambah_pecahan2));
        }

        if (parseFloat(tambah_pecahan4) > 0) {
            vartotaltambah = vartotaltambah + (parseFloat(varjumlah4) / parseFloat(tambah_pecahan4) / parseFloat(tambah_pecahan3) / parseFloat(tambah_pecahan2));
        }

        if (vartotaltambah > vartotalstok) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah melebihi stok");
            setTampilNotif(true);
            return;
        }

        setTambahJumlah1(parseFloat(varjumlah1));
        setTambahJumlah2(parseFloat(varjumlah2));
        setTambahJumlah3(parseFloat(varjumlah3));
        setTambahJumlah4(parseFloat(varjumlah4));
    }

    const konfirmasi_ubah_jumlah = () => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1].id_produk === tambah_id_produk) {
                tampung[awal - 1].jumlah1 = tambah_jumlah1;
                tampung[awal - 1].jumlah2 = tambah_jumlah2;
                tampung[awal - 1].jumlah3 = tambah_jumlah3;
                tampung[awal - 1].jumlah4 = tambah_jumlah4;
                break;
            }
            awal++;
        }

        setDataBelanja(tampung);

        if (metode_diskon === "persen") {
            let total = 0;
            let awal = 1;
            let akhir = tampung.length;
            while (awal <= akhir) {
                if (status_pembeli === false) {
                    total = total +
                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual1"])) +
                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual2"])) +
                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual3"])) +
                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual4"]))
                        ;
                }
                else {
                    total = total +
                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual_member1"])) +
                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual_member2"])) +
                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual_member3"])) +
                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual_member4"]))
                        ;
                }

                awal++;
            }

            let tampung_diskon = (parseFloat(diskon_persen) / 100) * total;
            setDiskon(tampung_diskon);
        }
        setTampilDialogTambah(false);
    }

    const tambah_belanja = (id_produk) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                let stok4 = 0;
                let stok3 = 0;
                let stok2 = 0;
                let stok1 = 0;

                // konversi stok
                if (parseFloat(tampung[awal - 1]["stok4"]) > 0) {
                    stok4 = parseFloat(tampung[awal - 1]["stok4"]) % parseFloat(tampung[awal - 1]["pecahan4"]);
                    stok3 = parseFloat(tampung[awal - 1]["stok3"]) + (Math.floor(parseFloat(tampung[awal - 1]["stok4"]) / parseFloat(tampung[awal - 1]["pecahan4"])));
                }
                else {
                    stok3 = parseFloat(tampung[awal - 1]["stok3"]);
                }

                if (parseFloat(stok3) > 0) {
                    stok3 = parseFloat(stok3) % parseFloat(tampung[awal - 1]["pecahan3"]);
                    stok2 = parseFloat(tampung[awal - 1]["stok2"]) + (Math.floor(parseFloat(tampung[awal - 1]["stok3"]) / parseFloat(tampung[awal - 1]["pecahan3"])));
                }
                else {
                    stok2 = parseFloat(tampung[awal - 1]["stok2"]);
                }

                if (parseFloat(stok2) > 0) {
                    stok2 = parseFloat(stok2) % parseFloat(tampung[awal - 1]["pecahan2"]);
                    stok1 = parseFloat(tampung[awal - 1]["stok1"]) + (Math.floor(parseFloat(tampung[awal - 1]["stok2"]) / parseFloat(tampung[awal - 1]["pecahan2"])));
                }
                else {
                    stok1 = parseFloat(tampung[awal - 1]["stok1"]);
                }

                // menambah
                if (stok1 > 0) {
                    if (parseFloat(tampung[awal - 1]["jumlah1"]) + 1 > stok1) {
                        setJenisNotif("warning");
                        setIsiNotif("Stok habis");
                        setTampilNotif(true);
                        return;
                    }
                    tampung[awal - 1]["jumlah1"] = parseFloat(tampung[awal - 1]["jumlah1"]) + 1;
                }
                else if (stok2 > 0) {
                    if (parseFloat(tampung[awal - 1]["jumlah2"]) + 1 > stok2) {
                        setJenisNotif("warning");
                        setIsiNotif("Stok habis");
                        setTampilNotif(true);
                        return;
                    }
                    tampung[awal - 1]["jumlah2"] = parseFloat(tampung[awal - 1]["jumlah2"]) + 1;
                }
                else if (stok3 > 0) {
                    if (parseFloat(tampung[awal - 1]["jumlah3"]) + 1 > stok3) {
                        setJenisNotif("warning");
                        setIsiNotif("Stok habis");
                        setTampilNotif(true);
                        return;
                    }
                    tampung[awal - 1]["jumlah3"] = parseFloat(tampung[awal - 1]["jumlah3"]) + 1;
                }
                else if (stok4 > 0) {
                    if (parseFloat(tampung[awal - 1]["jumlah4"]) + 1 > stok4) {
                        setJenisNotif("warning");
                        setIsiNotif("Stok habis");
                        setTampilNotif(true);
                        return;
                    }
                    tampung[awal - 1]["jumlah4"] = parseFloat(tampung[awal - 1]["jumlah4"]) + 1;
                }

                break;
            }
            awal++;
        }

        setDataBelanja(tampung);

        if (metode_diskon === "persen") {
            let total = 0;
            let awal = 1;
            let akhir = tampung.length;
            while (awal <= akhir) {
                if (status_pembeli === false) {
                    total = total +
                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual1"])) +
                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual2"])) +
                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual3"])) +
                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual4"]))
                        ;
                }
                else {
                    total = total +
                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual_member1"])) +
                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual_member2"])) +
                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual_member3"])) +
                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual_member4"]))
                        ;
                }

                awal++;
            }

            let tampung_diskon = (parseFloat(diskon_persen) / 100) * total;
            setDiskon(tampung_diskon);
        }
    }

    const kurang_belanja = (id_produk) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                let stok4 = 0;
                let stok3 = 0;
                let stok2 = 0;
                let stok1 = 0;

                // konversi stok
                if (parseFloat(tampung[awal - 1]["stok4"]) > 0) {
                    stok4 = parseFloat(tampung[awal - 1]["stok4"]) % parseFloat(tampung[awal - 1]["pecahan4"]);
                    stok3 = parseFloat(tampung[awal - 1]["stok3"]) + (Math.floor(parseFloat(tampung[awal - 1]["stok4"]) / parseFloat(tampung[awal - 1]["pecahan4"])));
                }
                else {
                    stok3 = parseFloat(tampung[awal - 1]["stok3"]);
                }

                if (parseFloat(stok3) > 0) {
                    stok3 = parseFloat(stok3) % parseFloat(tampung[awal - 1]["pecahan3"]);
                    stok2 = parseFloat(tampung[awal - 1]["stok2"]) + (Math.floor(parseFloat(tampung[awal - 1]["stok3"]) / parseFloat(tampung[awal - 1]["pecahan3"])));
                }
                else {
                    stok2 = parseFloat(tampung[awal - 1]["stok2"]);
                }

                if (parseFloat(stok2) > 0) {
                    stok2 = parseFloat(stok2) % parseFloat(tampung[awal - 1]["pecahan2"]);
                    stok1 = parseFloat(tampung[awal - 1]["stok1"]) + (Math.floor(parseFloat(tampung[awal - 1]["stok2"]) / parseFloat(tampung[awal - 1]["pecahan2"])));
                }
                else {
                    stok1 = parseFloat(tampung[awal - 1]["stok1"]);
                }

                // mengurangi
                if (stok1 > 0) {
                    if (parseFloat(tampung[awal - 1]["jumlah1"]) - 1 <= 0) {
                        setJenisNotif("warning");
                        setIsiNotif("Jumlah tidak boleh kosong");
                        setTampilNotif(true);
                        return;
                    }
                    tampung[awal - 1]["jumlah1"] = parseFloat(tampung[awal - 1]["jumlah1"]) - 1;
                }
                else if (stok2 > 0) {
                    if (parseFloat(tampung[awal - 1]["jumlah2"]) - 1 <= 0) {
                        setJenisNotif("warning");
                        setIsiNotif("Jumlah tidak boleh kosong");
                        setTampilNotif(true);
                        return;
                    }
                    tampung[awal - 1]["jumlah2"] = parseFloat(tampung[awal - 1]["jumlah2"]) - 1;
                }
                else if (stok3 > 0) {
                    if (parseFloat(tampung[awal - 1]["jumlah3"]) - 1 <= 0) {
                        setJenisNotif("warning");
                        setIsiNotif("Jumlah tidak boleh kosong");
                        setTampilNotif(true);
                        return;
                    }
                    tampung[awal - 1]["jumlah3"] = parseFloat(tampung[awal - 1]["jumlah3"]) - 1;
                }
                else if (stok4 > 0) {
                    if (parseFloat(tampung[awal - 1]["jumlah4"]) - 1 <= 0) {
                        setJenisNotif("warning");
                        setIsiNotif("Jumlah tidak boleh kosong");
                        setTampilNotif(true);
                        return;
                    }
                    tampung[awal - 1]["jumlah4"] = parseFloat(tampung[awal - 1]["jumlah4"]) - 1;
                }

                break;
            }
            awal++;
        }

        setDataBelanja(tampung);

        if (metode_diskon === "persen") {
            let total = 0;
            let awal = 1;
            let akhir = tampung.length;
            while (awal <= akhir) {
                if (status_pembeli === false) {
                    total = total +
                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual1"])) +
                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual2"])) +
                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual3"])) +
                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual4"]))
                        ;
                }
                else {
                    total = total +
                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual_member1"])) +
                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual_member2"])) +
                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual_member3"])) +
                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual_member4"]))
                        ;
                }

                awal++;
            }

            let tampung_diskon = (parseFloat(diskon_persen) / 100) * total;
            setDiskon(tampung_diskon);
        }
    }

    const hapus_belanja = (id_produk) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_produk"] === id_produk) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        setDataBelanja(tampung);
        if (metode_diskon === "persen") {
            let total = 0;
            let awal = 1;
            let akhir = tampung.length;
            while (awal <= akhir) {
                if (status_pembeli === false) {
                    total = total +
                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual1"])) +
                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual2"])) +
                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual3"])) +
                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual4"]))
                        ;
                }
                else {
                    total = total +
                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual_member1"])) +
                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual_member2"])) +
                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual_member3"])) +
                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual_member4"]))
                        ;
                }

                awal++;
            }

            let tampung_diskon = (parseFloat(diskon_persen) / 100) * total;
            setDiskon(tampung_diskon);
        }
    }

    const tampil_total = () => {
        let total = 0;
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (status_pembeli === false) {
                total = total +
                    (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual1"])) +
                    (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual2"])) +
                    (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual3"])) +
                    (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual4"]));
            }
            else {
                total = total +
                    (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual_member1"])) +
                    (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual_member2"])) +
                    (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual_member3"])) +
                    (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual_member4"]));
            }

            awal++;
        }

        return total;
    }

    const tampil_pembulatan = () => {
        let tampung_grand_total = tampil_total() - diskon;
        let tampung_pembulatan = Math.round(tampung_grand_total / 100) * 100 - tampung_grand_total;

        return tampung_pembulatan;
    }

    const ubah_diskon = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        setDiskon(event.target.value);
    }

    const perbaiki_diskon = (event) => {
        if (event.target.value === "") {
            setDiskon(0);
            return;
        }

        setDiskon(event.target.value);
    }

    const ubah_diskon_persen = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        setDiskonPersen(event.target.value);
        let tampung_diskon = (parseFloat(event.target.value) / 100) * tampil_total();
        setDiskon(tampung_diskon);
    }

    const perbaiki_diskon_persen = (event) => {
        if (event.target.value === "") {
            setDiskonPersen(0);
            setDiskon(0);
            return;
        }

        let tampung_diskon = parseFloat((event.target.value / 100) * tampil_total());

        setDiskonPersen(event.target.value);
        setDiskon(tampung_diskon);
    }

    const tampil_draft = (halaman_baru) => {
        fetch(props.aplikasi + '/penjualan/tampil_draft_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        jumlah_baris: jumlah_baris_draft,
                        halaman: halaman_baru
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataDraft(data.data);
                    setJumlahHalamanDraft(Math.ceil(data.jumlah_data / jumlah_baris_draft));
                }
                else {
                    setDataDraft([]);
                    setJumlahHalamanDraft(0);
                }
            })
            .catch(error => {
                setDataDraft([]);
                setJumlahHalamanDraft(0);
            }
            );
    }

    const pindah_halaman_draft = (halaman_draft_baru) => {
        setHalamanDraft(halaman_draft_baru);
        tampil_draft(halaman_draft_baru);
    }

    const ubah_jumlah_bayar = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahBayar(event.target.value);
    }

    const perbaiki_jumlah_bayar = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar(event.target.value);
        }
        else {
            setJumlahBayar(0);
        }
    }

    const ubah_jumlah_bayar2 = (event) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahBayar2(event.target.value);
    }

    const perbaiki_jumlah_bayar2 = (event) => {
        if (parseFloat(event.target.value) > 0) {
            setJumlahBayar2(event.target.value);
        }
        else {
            setJumlahBayar2(0);
        }
    }

    const tampil_kembalian = () => {
        return parseFloat(jumlah_bayar) + parseFloat(jumlah_bayar2) - (tampil_total() - parseFloat(diskon) + tampil_pembulatan());
    }

    const simpan = () => {
        let id_sales = "";
        let nama_sales = "";
        if (sales !== null) {
            id_sales = sales.id_pengguna;
            nama_sales = sales.nama;
        }

        let id_pelanggan = "";
        let nama_pelanggan = "";
        if (pelanggan !== null) {
            id_pelanggan = pelanggan.id_pelanggan;
            nama_pelanggan = pelanggan.nama;
        }

        if (metode_bayar.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Metode bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        if (id_pelanggan === "" && metode_bayar.trim() === "99") {
            setJenisNotif("warning");
            setIsiNotif("Pembeli umum tidak bisa hutang");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        if (metode_bayar !== "99" && parseFloat(jumlah_bayar) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        if (metode_bayar2 !== "99" && jumlah_metode_bayar === 2 && jumlah_bayar2 === 0) {
            setJenisNotif("warning");
            setIsiNotif("Jumlah bayar Kedua wajib diisi");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar2").focus();
            return;
        }

        if ((metode_bayar !== "99" && metode_bayar2 !== "99") && jumlah_metode_bayar === 2 && tampil_kembalian() !== 0) {
            setJenisNotif("warning");
            setIsiNotif("Bila menggunakan 2 metode pembayaran, uang harus pas");
            setTampilNotif(true);
            document.getElementById("jumlah_bayar").focus();
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/penjualan/simpan_pembayaran.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        id_draft: id_draft,
                        id_sales: id_sales,
                        nama_sales: nama_sales,
                        mobil: mobil,
                        id_pelanggan: id_pelanggan,
                        nama_pelanggan: nama_pelanggan,
                        id_metode_bayar: metode_bayar,
                        id_metode_bayar2: metode_bayar2,
                        subtotal: tampil_total(),
                        diskon: diskon,
                        pembulatan: tampil_pembulatan(),
                        grand_total: tampil_total() - parseFloat(diskon) + tampil_pembulatan(),
                        jumlah_bayar: jumlah_bayar,
                        jumlah_bayar2: jumlah_bayar2,
                        kembalian: tampil_kembalian(),
                        data_belanja: data_belanja,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setIdPenjualan(data.id_penjualan);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const simpan_draft = () => {
        if (data_belanja.length === 0) {
            setJenisNotif("warning");
            setIsiNotif("Produk belum dipilih");
            setTampilNotif(true);
            return;
        }

        let id_sales = "";
        let nama_sales = "";
        if (sales !== null) {
            id_sales = sales.id_pengguna;
            nama_sales = sales.nama;
        }

        let id_pelanggan = "";
        let nama_pelanggan = "";
        if (pelanggan !== null) {
            id_pelanggan = pelanggan.id_pelanggan;
            nama_pelanggan = pelanggan.nama;
        }

        setProses(true);
        fetch(props.aplikasi + '/penjualan/simpan_pembayaran_draft.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        id_draft: id_draft,
                        id_sales: id_sales,
                        nama_sales: nama_sales,
                        mobil: mobil,
                        id_pelanggan: id_pelanggan,
                        nama_pelanggan: nama_pelanggan,
                        id_metode_bayar: metode_bayar,
                        id_metode_bayar2: metode_bayar2,
                        subtotal: tampil_total(),
                        metode_diskon: metode_diskon,
                        diskon_persen: diskon_persen,
                        diskon: diskon,
                        pembulatan: tampil_pembulatan(),
                        grand_total: tampil_total() - parseFloat(diskon) + tampil_pembulatan(),
                        data_belanja: data_belanja,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    batal();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    const batal = () => {
        fetch(props.aplikasi + '/penjualan/tampil_metode_bayar.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                    setTampilBlokir(true);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
                setTampilBlokir(true);
            }
            );

        fetch(props.aplikasi + '/penjualan/tampil_sales.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataSales(data.data);
                }
                else {
                    setDataSales([]);
                }
            })
            .catch(error => {
                setDataSales([]);
                setTampilBlokir(true);
            }
            );

        fetch(props.aplikasi + '/penjualan/tampil_pelanggan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPelanggan(data.data);
                }
                else {
                    setDataPelanggan([]);
                }
            })
            .catch(error => {
                setDataPelanggan([]);
                setTampilBlokir(true);
            }
            );

        setKode("");
        setDataBelanja([]);
        setJumlahMetodeBayar(1);
        setMetodeBayar("1");
        setMetodeBayar2("");
        setJumlahBayar(0);
        setJumlahBayar2(0);
        setStatusSales(false);
        setLabelStatusSales("Tidak ada");
        setStatusPembeli(false);
        setLabelStatusPembeli("Umum");
        setSales(null);
        setMobil("");
        setPelanggan(null);
        setMetodeDiskon("nominal");
        setDiskon(0);
        setDiskonPersen(0);
        setIdPenjualan("");
        setIdDraft("");
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={tampil_blokir}
            >
                <Typography variant="h6" color="white">
                    Halaman Gagal Dimuat Dengan Sempurna. Silakan Reload Atau Tekan F5
                </Typography>
            </Backdrop>

            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Dialog
                maxWidth="xs"
                fullWidth
                open={tampil_dialog_harga}
                onClose={() => setTampilDialogHarga(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    Rincian Harga {tambah_nama}
                </DialogTitle>
                <DialogContent>
                    <Grid container item xs={12}>
                        <Grid item xs={8}>
                            <Typography variant="body2">
                                1 {tambah_satuan1}
                            </Typography>

                            <Typography variant="body2" sx={{ color: "red" }}>
                                {" (" + tambah_pecahan2 + " " + tambah_satuan2 + ")"}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                Rp. {format_rupiah(parseFloat(tambah_harga_jual1))}
                            </Typography>
                        </Grid>

                        <br /><br />

                        {tambah_id_satuan2 !== "0"
                            ?
                            <>
                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        1 {tambah_satuan2}
                                    </Typography>

                                    <Typography variant="body2" sx={{ color: "red" }}>
                                        {" (" + tambah_pecahan3 + " " + tambah_satuan3 + ")"}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                        Rp. {format_rupiah(parseFloat(tambah_harga_jual2))}
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <></>
                        }

                        {tambah_id_satuan3 !== "0"
                            ?
                            <>
                                <br /><br />

                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        1 {tambah_satuan3}
                                    </Typography>

                                    <Typography variant="body2" sx={{ color: "red" }}>
                                        {" (" + tambah_pecahan4 + " " + tambah_satuan4 + ")"}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                        Rp. {format_rupiah(parseFloat(tambah_harga_jual3))}
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <></>
                        }

                        {tambah_id_satuan4 !== "0"
                            ?
                            <>
                                <br /><br />

                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        1 {tambah_satuan4}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                        Rp. {format_rupiah(parseFloat(tambah_harga_jual4))}
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <></>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogHarga(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="xs"
                fullWidth
                open={tampil_dialog_diskon_member}
                onClose={() => setTampilDialogDiskonMember(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    Rincian Diskon {tambah_nama}
                </DialogTitle>
                <DialogContent>
                    <Grid container item xs={12}>
                        <Grid item xs={8}>
                            <Typography variant="body2">
                                1 {tambah_satuan1}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="body2" align="right" sx={{ color: "red" }}>
                                Rp. {format_rupiah(parseFloat(tambah_harga_jual1) - parseFloat(tambah_harga_jual_member1))}
                            </Typography>
                        </Grid>

                        {tambah_id_satuan2 !== "0"
                            ?
                            <>
                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        1 {tambah_satuan2}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body2" align="right" sx={{ color: "red" }}>
                                        Rp. {format_rupiah(parseFloat(tambah_harga_jual2) - parseFloat(tambah_harga_jual_member2))}
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <></>
                        }

                        {tambah_id_satuan3 !== "0"
                            ?
                            <>
                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        1 {tambah_satuan3}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body2" align="right" sx={{ color: "red" }}>
                                        Rp. {format_rupiah(parseFloat(tambah_harga_jual3) - parseFloat(tambah_harga_jual_member3))}
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <></>
                        }

                        {tambah_id_satuan4 !== "0"
                            ?
                            <>
                                <Grid item xs={8}>
                                    <Typography variant="body2">
                                        1 {tambah_satuan4}
                                    </Typography>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="body2" align="right" sx={{ color: "red" }}>
                                        Rp. {format_rupiah(parseFloat(tambah_harga_jual4) - parseFloat(tambah_harga_jual_member4))}
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <></>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogDiskonMember(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={tampil_dialog_tambah}
                onClose={() => setTampilDialogTambah(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    Input Jumlah {tambah_nama}
                </DialogTitle>
                <DialogContent>
                    <Grid container item xs={12}>
                        <Grid item xs={2}>
                            <Typography variant="body2">
                                Jumlah 1
                            </Typography>
                        </Grid>

                        <Grid item xs={5}>
                            <Grid
                                item xs={12}
                            >
                                <Fab
                                    color="primary"
                                    size="small"
                                    style={{ backgroundColor: "gray" }}
                                    onClick={() => {
                                        if (tambah_jumlah1 - 1 >= 0) {
                                            ubah_jumlah(
                                                tambah_jumlah1 - 1,
                                                tambah_jumlah2,
                                                tambah_jumlah3,
                                                tambah_jumlah4
                                            )
                                        }
                                    }}
                                >
                                    <RemoveIcon />
                                </Fab>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        style: { textAlign: "center", width: "50px" }
                                    }}
                                    value={tambah_jumlah1}
                                    onChange={(event) =>
                                        ubah_jumlah(
                                            event.target.value,
                                            tambah_jumlah2,
                                            tambah_jumlah3,
                                            tambah_jumlah4
                                        )
                                    }
                                    onBlur={(event) => {
                                        if (event.target.value === "") {
                                            setTambahJumlah1(0);
                                        }
                                    }}
                                />
                                <Fab
                                    color="primary"
                                    size="small"
                                    style={{ backgroundColor: "gray" }}
                                    onClick={() =>
                                        ubah_jumlah(
                                            tambah_jumlah1 + 1,
                                            tambah_jumlah2,
                                            tambah_jumlah3,
                                            tambah_jumlah4
                                        )
                                    }
                                >
                                    <AddIcon />
                                </Fab>
                            </Grid>
                        </Grid>

                        <Grid item xs={5}>
                            <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                Stok : {tambah_stok1 + " " + tambah_satuan1}
                            </Typography>

                            <Typography variant="body2" align="right" sx={{ color: "red" }}>
                                1 {tambah_satuan1 + " = " + tambah_pecahan2 + " " + tambah_satuan2}
                            </Typography>
                        </Grid>
                    </Grid>

                    {tambah_id_satuan2 !== "0" ?
                        <>
                            <br />

                            <Grid container item xs={12}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Jumlah 2
                                    </Typography>
                                </Grid>

                                <Grid item xs={5}>
                                    <Grid
                                        item xs={12}
                                    >
                                        <Fab
                                            color="primary"
                                            size="small"
                                            style={{ backgroundColor: "gray" }}
                                            onClick={() => {
                                                if (tambah_jumlah2 - 1 >= 0) {
                                                    ubah_jumlah(
                                                        tambah_jumlah1,
                                                        tambah_jumlah2 - 1,
                                                        tambah_jumlah3,
                                                        tambah_jumlah4
                                                    )
                                                }
                                            }}
                                        >
                                            <RemoveIcon />
                                        </Fab>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            inputProps={{
                                                style: { textAlign: "center", width: "50px" }
                                            }}
                                            value={tambah_jumlah2}
                                            onChange={(event) =>
                                                ubah_jumlah(
                                                    tambah_jumlah1,
                                                    event.target.value,
                                                    tambah_jumlah3,
                                                    tambah_jumlah4
                                                )
                                            }
                                            onBlur={(event) => {
                                                if (event.target.value === "") {
                                                    setTambahJumlah2(0);
                                                }
                                            }}
                                        />
                                        <Fab
                                            color="primary"
                                            size="small"
                                            style={{ backgroundColor: "gray" }}
                                            onClick={() =>
                                                ubah_jumlah(
                                                    tambah_jumlah1,
                                                    tambah_jumlah2 + 1,
                                                    tambah_jumlah3,
                                                    tambah_jumlah4
                                                )
                                            }
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>

                                <Grid item xs={5}>
                                    <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                        Stok : {tambah_stok2 + " " + tambah_satuan2}
                                    </Typography>

                                    <Typography variant="body2" align="right" sx={{ color: "red" }}>
                                        1 {tambah_satuan2 + " = " + tambah_pecahan3 + " " + tambah_satuan3}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <></>
                    }

                    {tambah_id_satuan3 !== "0" ?
                        <>
                            <br />

                            <Grid container item xs={12}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Jumlah 3
                                    </Typography>
                                </Grid>

                                <Grid item xs={5}>
                                    <Grid
                                        item xs={12}
                                    >
                                        <Fab
                                            color="primary"
                                            size="small"
                                            style={{ backgroundColor: "gray" }}
                                            onClick={() => {
                                                if (tambah_jumlah3 - 1 >= 0) {
                                                    ubah_jumlah(
                                                        tambah_jumlah1,
                                                        tambah_jumlah2,
                                                        tambah_jumlah3 - 1,
                                                        tambah_jumlah4
                                                    )
                                                }
                                            }}
                                        >
                                            <RemoveIcon />
                                        </Fab>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            inputProps={{
                                                style: { textAlign: "center", width: "50px" }
                                            }}
                                            value={tambah_jumlah3}
                                            onChange={(event) =>
                                                ubah_jumlah(
                                                    tambah_jumlah1,
                                                    tambah_jumlah2,
                                                    event.target.value,
                                                    tambah_jumlah4
                                                )
                                            }
                                            onBlur={(event) => {
                                                if (event.target.value === "") {
                                                    setTambahJumlah3(0);
                                                }
                                            }}
                                        />
                                        <Fab
                                            color="primary"
                                            size="small"
                                            style={{ backgroundColor: "gray" }}
                                            onClick={() =>
                                                ubah_jumlah(
                                                    tambah_jumlah1,
                                                    tambah_jumlah2,
                                                    tambah_jumlah3 + 1,
                                                    tambah_jumlah4
                                                )
                                            }
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>

                                <Grid item xs={5}>
                                    <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                        Stok : {tambah_stok3 + " " + tambah_satuan3}
                                    </Typography>

                                    <Typography variant="body2" align="right" sx={{ color: "red" }}>
                                        1 {tambah_satuan3 + " = " + tambah_pecahan4 + " " + tambah_satuan4}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <></>
                    }

                    {tambah_id_satuan4 !== "0" ?
                        <>
                            <br />

                            <Grid container item xs={12}>
                                <Grid item xs={2}>
                                    <Typography variant="body2">
                                        Jumlah 4
                                    </Typography>
                                </Grid>

                                <Grid item xs={5}>
                                    <Grid
                                        item xs={12}
                                    >
                                        <Fab
                                            color="primary"
                                            size="small"
                                            style={{ backgroundColor: "gray" }}
                                            onClick={() => {
                                                if (tambah_jumlah4 - 1 >= 0) {
                                                    ubah_jumlah(
                                                        tambah_jumlah1,
                                                        tambah_jumlah2,
                                                        tambah_jumlah3,
                                                        tambah_jumlah4 - 1
                                                    )
                                                }
                                            }}
                                        >
                                            <RemoveIcon />
                                        </Fab>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            inputProps={{
                                                style: { textAlign: "center", width: "50px" }
                                            }}
                                            value={tambah_jumlah4}
                                            onChange={(event) =>
                                                ubah_jumlah(
                                                    tambah_jumlah1,
                                                    tambah_jumlah2,
                                                    tambah_jumlah3,
                                                    event.target.value
                                                )
                                            }
                                            onBlur={(event) => {
                                                if (event.target.value === "") {
                                                    setTambahJumlah4(0);
                                                }
                                            }}
                                        />
                                        <Fab
                                            color="primary"
                                            size="small"
                                            style={{ backgroundColor: "gray" }}
                                            onClick={() =>
                                                ubah_jumlah(
                                                    tambah_jumlah1,
                                                    tambah_jumlah2,
                                                    tambah_jumlah3,
                                                    tambah_jumlah4 + 1
                                                )
                                            }
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>

                                <Grid item xs={5}>
                                    <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                        Stok : {tambah_stok4 + " " + tambah_satuan4}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <></>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                        onClick={() => konfirmasi_ubah_jumlah()}
                    >
                        <Typography variant="body2" noWrap={true}>Tambah</Typography>
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogTambah(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_diskon}
                onClose={() => setTampilDialogDiskon(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Input Diskon
                </DialogTitle>
                <DialogContent>
                    <Grid container item>
                        <Grid item xs={4} sm={4} md={3}>
                            <Typography variant="body2">
                                Diskon
                            </Typography>
                        </Grid>

                        <Grid container item spacing={1} xs={8} sm={8} md={9}>
                            <Grid container spacing={1} item xs={6}>
                                <Grid
                                    item xs={12}
                                >
                                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                                        <LoadingButton
                                            loadingPosition="start"
                                            loading={proses}
                                            variant={metode_diskon === "nominal" ? "contained" : "outlined"}
                                            onClick={() => {
                                                setMetodeDiskon("nominal");
                                                setDiskon(0);
                                                setDiskonPersen(0);
                                            }}
                                        >
                                            Rp.
                                        </LoadingButton>
                                        <LoadingButton
                                            loadingPosition="start"
                                            loading={proses}
                                            variant={metode_diskon !== "nominal" ? "contained" : "outlined"}
                                            onClick={() => {
                                                setMetodeDiskon("persen");
                                                setDiskon(0);
                                                setDiskonPersen(0);
                                            }}
                                        >
                                            %
                                        </LoadingButton>
                                    </ButtonGroup>
                                </Grid>

                                <Grid
                                    item xs={12}
                                    sx={
                                        metode_diskon === "nominal" ? { display: "none" } : { display: "" }
                                    }
                                >
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={diskon_persen}
                                        onChange={(e) => ubah_diskon_persen(e)}
                                        onBlur={(e) => perbaiki_diskon_persen(e)}
                                    />
                                </Grid>

                                <Grid
                                    item xs={12}
                                    sx={
                                        metode_diskon === "persen" ? { display: "none" } : { display: "" }
                                    }
                                >
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        disabled={metode_diskon === "nominal" ? false : true}
                                        value={diskon}
                                        onChange={(e) => ubah_diskon(e)}
                                        onBlur={(e) => perbaiki_diskon(e)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item xs={6}>
                                <Grid
                                    item xs={12}
                                >
                                    <Typography variant="h6" align="right" sx={{ color: "white" }}>
                                        Rp. {format_rupiah(parseFloat(diskon))}
                                    </Typography>
                                </Grid>

                                <Grid
                                    item xs={12}
                                >
                                    <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                        Rp. {format_rupiah(parseFloat(diskon))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogDiskon(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_cari_produk}
                onClose={() => setTampilDialogCariProduk(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    Pencarian Produk
                </DialogTitle>
                <DialogContent>
                    <TextField
                        id="pencarian"
                        label="Cari nama produk disini"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={pencarian}
                        sx={{
                            marginTop: "10px",
                            marginBottom: "10px"
                        }}
                        onChange={(event) => setPencarian(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                cari_produk();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined sx={{ color: "#219cd9" }} />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Card
                        sx={{
                            backgroundColor: "white",
                            padding: "10px"
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Nama</StyledTableCell>
                                        <StyledTableCell align="center">Harga</StyledTableCell>
                                        <StyledTableCell align="center">Stok</StyledTableCell>
                                        <StyledTableCell align="center">Aksi</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data_produk.map((tampung_produk) => {
                                        return (
                                            <StyledTableRow key={tampung_produk.id_produk}>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_produk.nama}</Typography></TableCell>
                                                <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>
                                                    {status_pembeli === false ?
                                                        format_rupiah(parseFloat(tampung_produk.harga_jual1))
                                                        :
                                                        format_rupiah(parseFloat(tampung_produk.harga_jual_member1))
                                                    }
                                                </Typography></TableCell>
                                                <TableCell size="small" align="right">
                                                    <Typography variant="body2" noWrap={true}>
                                                        {tampil_konversi_stok(
                                                            tampung_produk.jumlah1,
                                                            tampung_produk.satuan1,
                                                            tampung_produk.jumlah2,
                                                            tampung_produk.satuan2,
                                                            tampung_produk.pecahan2,
                                                            tampung_produk.jumlah3,
                                                            tampung_produk.satuan3,
                                                            tampung_produk.pecahan3,
                                                            tampung_produk.jumlah4,
                                                            tampung_produk.satuan4,
                                                            tampung_produk.pecahan4
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell size="small" align="center">
                                                    <Fab
                                                        color="primary"
                                                        size="small"
                                                        style={{ backgroundColor: "gray" }}
                                                        onClick={() => {
                                                            let cari_id_jenis = tampung_produk.id_jenis;
                                                            let cari_jenis = tampung_produk.jenis;
                                                            let cari_id_produk = tampung_produk.id_produk;
                                                            let cari_nama = tampung_produk.nama;
                                                            let cari_id_satuan1 = tampung_produk.id_satuan1;
                                                            let cari_harga_beli1 = tampung_produk.harga_beli1;
                                                            let cari_harga_jual1 = tampung_produk.harga_jual1;
                                                            let cari_harga_jual_member1 = tampung_produk.harga_jual_member1;
                                                            let cari_satuan1 = tampung_produk.satuan1;
                                                            let cari_id_satuan2 = tampung_produk.id_satuan2;
                                                            let cari_harga_beli2 = tampung_produk.harga_beli2;
                                                            let cari_harga_jual2 = tampung_produk.harga_jual2;
                                                            let cari_harga_jual_member2 = tampung_produk.harga_jual_member2;
                                                            let cari_satuan2 = tampung_produk.satuan2;
                                                            let cari_pecahan2 = tampung_produk.pecahan2;
                                                            let cari_id_satuan3 = tampung_produk.id_satuan3;
                                                            let cari_harga_beli3 = tampung_produk.harga_beli3;
                                                            let cari_harga_jual3 = tampung_produk.harga_jual3;
                                                            let cari_harga_jual_member3 = tampung_produk.harga_jual_member3;
                                                            let cari_satuan3 = tampung_produk.satuan3
                                                            let cari_pecahan3 = tampung_produk.pecahan3;
                                                            let cari_id_satuan4 = tampung_produk.id_satuan4;
                                                            let cari_harga_beli4 = tampung_produk.harga_beli4;
                                                            let cari_harga_jual4 = tampung_produk.harga_jual4;
                                                            let cari_harga_jual_member4 = tampung_produk.harga_jual_member4;
                                                            let cari_satuan4 = tampung_produk.satuan4;
                                                            let cari_pecahan4 = tampung_produk.pecahan4;

                                                            let cari_stok4 = 0;
                                                            let cari_stok3 = 0;
                                                            let cari_stok2 = 0;
                                                            let cari_stok1 = 0;
                                                            if (parseFloat(tampung_produk.jumlah4) > 0) {
                                                                cari_stok4 = parseFloat(tampung_produk.jumlah4) % parseFloat(cari_pecahan4);
                                                                cari_stok3 = parseFloat(tampung_produk.jumlah3) + (Math.floor(parseFloat(tampung_produk.jumlah4) / parseFloat(cari_pecahan4)));
                                                            }
                                                            else {
                                                                cari_stok3 = parseFloat(tampung_produk.jumlah3);
                                                            }

                                                            if (cari_stok3 > 0) {
                                                                cari_stok3 = cari_stok3 % parseFloat(cari_pecahan3);
                                                                cari_stok2 = parseFloat(tampung_produk.jumlah2) + (Math.floor(parseFloat(tampung_produk.jumlah3) / parseFloat(cari_pecahan3)));
                                                            }
                                                            else {
                                                                cari_stok2 = parseFloat(tampung_produk.jumlah2);
                                                            }

                                                            if (cari_stok2 > 0) {
                                                                cari_stok2 = cari_stok2 % parseFloat(cari_pecahan2);
                                                                cari_stok1 = parseFloat(tampung_produk.jumlah1) + (Math.floor(parseFloat(tampung_produk.jumlah2) / parseFloat(cari_pecahan2)));
                                                            }
                                                            else {
                                                                cari_stok1 = parseFloat(tampung_produk.jumlah1);
                                                            }

                                                            if (cari_stok1 <= 0 && cari_stok2 <= 0 && cari_stok3 <= 0 && cari_stok4 <= 0) {
                                                                setJenisNotif("warning");
                                                                setIsiNotif("Stok habis");
                                                                setTampilNotif(true);
                                                                return;
                                                            }

                                                            let tampung = [...data_belanja];
                                                            let awal = 1;
                                                            let akhir = tampung.length;
                                                            if (akhir > 0) {
                                                                while (awal <= akhir) {
                                                                    if (cari_id_produk === tampung[awal - 1]["id_produk"]) {
                                                                        let vartotalstok = parseFloat(tampung_produk.jumlah1);

                                                                        if (parseFloat(cari_pecahan2) > 0) {
                                                                            vartotalstok = vartotalstok + (parseFloat(tampung_produk.jumlah2) / parseFloat(cari_pecahan2));
                                                                        }

                                                                        if (parseFloat(cari_pecahan3) > 0) {
                                                                            vartotalstok = vartotalstok + (parseFloat(tampung_produk.jumlah3) / parseFloat(cari_pecahan3) / parseFloat(cari_pecahan2));
                                                                        }

                                                                        if (parseFloat(cari_pecahan4) > 0) {
                                                                            vartotalstok = vartotalstok + (parseFloat(tampung_produk.jumlah2) / parseFloat(cari_pecahan4) / parseFloat(cari_pecahan3) / parseFloat(cari_pecahan2));
                                                                        }

                                                                        let cari_jumlah1 = parseFloat(tampung[awal - 1]["jumlah1"]);
                                                                        let cari_jumlah2 = parseFloat(tampung[awal - 1]["jumlah2"]);
                                                                        let cari_jumlah3 = parseFloat(tampung[awal - 1]["jumlah3"]);
                                                                        let cari_jumlah4 = parseFloat(tampung[awal - 1]["jumlah4"]);

                                                                        if (cari_jumlah1 > 0) {
                                                                            cari_jumlah1 = cari_jumlah1 + 1;
                                                                        }
                                                                        else if (cari_jumlah2 > 0) {
                                                                            cari_jumlah2 = cari_jumlah2 + 1;
                                                                        }
                                                                        else if (cari_jumlah3 > 0) {
                                                                            cari_jumlah3 = cari_jumlah3 + 1;
                                                                        }
                                                                        else if (cari_jumlah4 > 0) {
                                                                            cari_jumlah4 = cari_jumlah4 + 1;
                                                                        }

                                                                        let vartotaltambah = parseFloat(cari_jumlah1);
                                                                        if (parseFloat(cari_pecahan2) > 0) {
                                                                            vartotaltambah = vartotaltambah + (parseFloat(cari_jumlah2) / parseFloat(cari_pecahan2));
                                                                        }

                                                                        if (parseFloat(tambah_pecahan3) > 0) {
                                                                            vartotaltambah = vartotaltambah + (parseFloat(cari_jumlah3) / parseFloat(cari_pecahan3) / parseFloat(cari_pecahan2));
                                                                        }

                                                                        if (parseFloat(tambah_pecahan4) > 0) {
                                                                            vartotaltambah = vartotaltambah + (parseFloat(cari_jumlah4) / parseFloat(cari_pecahan4) / parseFloat(cari_pecahan3) / parseFloat(cari_pecahan2));
                                                                        }

                                                                        if (vartotaltambah > vartotalstok) {
                                                                            setJenisNotif("warning");
                                                                            setIsiNotif("Jumlah melebihi stok");
                                                                            setTampilNotif(true);
                                                                            return;
                                                                        }

                                                                        tampung[awal - 1]["jumlah1"] = cari_jumlah1;
                                                                        tampung[awal - 1]["jumlah2"] = cari_jumlah2;
                                                                        tampung[awal - 1]["jumlah3"] = cari_jumlah3;
                                                                        tampung[awal - 1]["jumlah4"] = cari_jumlah4;

                                                                        setDataBelanja(tampung);
                                                                        setKode("");
                                                                        return;
                                                                    }
                                                                    awal++;
                                                                }

                                                                let cari_jumlah1 = 0;
                                                                let cari_jumlah2 = 0;
                                                                let cari_jumlah3 = 0;
                                                                let cari_jumlah4 = 0;

                                                                if (cari_stok1 > 0) {
                                                                    if (cari_stok1 >= 1) {
                                                                        cari_jumlah1 = 1;
                                                                    }
                                                                    else {
                                                                        cari_jumlah1 = cari_stok1;
                                                                    }
                                                                }
                                                                else if (cari_stok2 > 0) {
                                                                    if (cari_stok2 >= 1) {
                                                                        cari_jumlah2 = 1;
                                                                    }
                                                                    else {
                                                                        cari_jumlah2 = cari_stok2;
                                                                    }
                                                                }
                                                                else if (cari_stok3 > 0) {
                                                                    if (cari_stok3 >= 1) {
                                                                        cari_jumlah3 = 1;
                                                                    }
                                                                    else {
                                                                        cari_jumlah3 = cari_stok3;
                                                                    }
                                                                }
                                                                else if (cari_stok4 > 0) {
                                                                    if (cari_stok4 >= 1) {
                                                                        cari_jumlah4 = 1;
                                                                    }
                                                                    else {
                                                                        cari_jumlah4 = cari_stok4;
                                                                    }
                                                                }

                                                                tampung.push({
                                                                    "id_jenis": cari_id_jenis,
                                                                    "jenis": cari_jenis,
                                                                    "id_produk": cari_id_produk,
                                                                    "nama": cari_nama,
                                                                    "id_satuan1": cari_id_satuan1,
                                                                    "harga_beli1": cari_harga_beli1,
                                                                    "harga_jual1": cari_harga_jual1,
                                                                    "harga_jual_member1": cari_harga_jual_member1,
                                                                    "satuan1": cari_satuan1,
                                                                    "stok1": cari_stok1,
                                                                    "jumlah1": cari_jumlah1,
                                                                    "id_satuan2": cari_id_satuan2,
                                                                    "harga_beli2": cari_harga_beli2,
                                                                    "harga_jual2": cari_harga_jual2,
                                                                    "harga_jual_member2": cari_harga_jual_member2,
                                                                    "satuan2": cari_satuan2,
                                                                    "stok2": cari_stok2,
                                                                    "jumlah2": cari_jumlah2,
                                                                    "pecahan2": cari_pecahan2,
                                                                    "id_satuan3": cari_id_satuan3,
                                                                    "harga_beli3": cari_harga_beli3,
                                                                    "harga_jual3": cari_harga_jual3,
                                                                    "harga_jual_member3": cari_harga_jual_member3,
                                                                    "satuan3": cari_satuan3,
                                                                    "stok3": cari_stok3,
                                                                    "jumlah3": cari_jumlah3,
                                                                    "pecahan3": cari_pecahan3,
                                                                    "id_satuan4": cari_id_satuan4,
                                                                    "harga_beli4": cari_harga_beli4,
                                                                    "harga_jual4": cari_harga_jual4,
                                                                    "harga_jual_member4": cari_harga_jual_member4,
                                                                    "satuan4": cari_satuan4,
                                                                    "stok4": cari_stok4,
                                                                    "jumlah4": cari_jumlah4,
                                                                    "pecahan4": cari_pecahan4
                                                                });
                                                            }
                                                            else {
                                                                let cari_jumlah1 = 0;
                                                                let cari_jumlah2 = 0;
                                                                let cari_jumlah3 = 0;
                                                                let cari_jumlah4 = 0;

                                                                if (cari_stok1 > 0) {
                                                                    if (cari_stok1 >= 1) {
                                                                        cari_jumlah1 = 1;
                                                                    }
                                                                    else {
                                                                        cari_jumlah1 = cari_stok1;
                                                                    }
                                                                }
                                                                else if (cari_stok2 > 0) {
                                                                    if (cari_stok2 >= 1) {
                                                                        cari_jumlah2 = 1;
                                                                    }
                                                                    else {
                                                                        cari_jumlah2 = cari_stok2;
                                                                    }
                                                                }
                                                                else if (cari_stok3 > 0) {
                                                                    if (cari_stok3 >= 1) {
                                                                        cari_jumlah3 = 1;
                                                                    }
                                                                    else {
                                                                        cari_jumlah3 = cari_stok3;
                                                                    }
                                                                }
                                                                else if (cari_stok4 > 0) {
                                                                    if (cari_stok4 >= 1) {
                                                                        cari_jumlah4 = 1;
                                                                    }
                                                                    else {
                                                                        cari_jumlah4 = cari_stok4;
                                                                    }
                                                                }

                                                                tampung.push({
                                                                    "id_jenis": cari_id_jenis,
                                                                    "jenis": cari_jenis,
                                                                    "id_produk": cari_id_produk,
                                                                    "nama": cari_nama,
                                                                    "id_satuan1": cari_id_satuan1,
                                                                    "harga_beli1": cari_harga_beli1,
                                                                    "harga_jual1": cari_harga_jual1,
                                                                    "harga_jual_member1": cari_harga_jual_member1,
                                                                    "satuan1": cari_satuan1,
                                                                    "stok1": cari_stok1,
                                                                    "jumlah1": cari_jumlah1,
                                                                    "id_satuan2": cari_id_satuan2,
                                                                    "harga_beli2": cari_harga_beli2,
                                                                    "harga_jual2": cari_harga_jual2,
                                                                    "harga_jual_member2": cari_harga_jual_member2,
                                                                    "satuan2": cari_satuan2,
                                                                    "stok2": cari_stok2,
                                                                    "jumlah2": cari_jumlah2,
                                                                    "pecahan2": cari_pecahan2,
                                                                    "id_satuan3": cari_id_satuan3,
                                                                    "harga_beli3": cari_harga_beli3,
                                                                    "harga_jual3": cari_harga_jual3,
                                                                    "harga_jual_member3": cari_harga_jual_member3,
                                                                    "satuan3": cari_satuan3,
                                                                    "stok3": cari_stok3,
                                                                    "jumlah3": cari_jumlah3,
                                                                    "pecahan3": cari_pecahan3,
                                                                    "id_satuan4": cari_id_satuan4,
                                                                    "harga_beli4": cari_harga_beli4,
                                                                    "harga_jual4": cari_harga_jual4,
                                                                    "harga_jual_member4": cari_harga_jual_member4,
                                                                    "satuan4": cari_satuan4,
                                                                    "stok4": cari_stok4,
                                                                    "jumlah4": cari_jumlah4,
                                                                    "pecahan4": cari_pecahan4
                                                                });
                                                            }

                                                            let total = 0;
                                                            awal = 1;
                                                            while (awal <= akhir) {
                                                                if (status_pembeli === false) {
                                                                    total = total +
                                                                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual1"])) +
                                                                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual2"])) +
                                                                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual3"])) +
                                                                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual4"]))
                                                                        ;
                                                                }
                                                                else {
                                                                    total = total +
                                                                        (parseFloat(tampung[awal - 1]["jumlah1"]) * parseFloat(tampung[awal - 1]["harga_jual_member1"])) +
                                                                        (parseFloat(tampung[awal - 1]["jumlah2"]) * parseFloat(tampung[awal - 1]["harga_jual_member2"])) +
                                                                        (parseFloat(tampung[awal - 1]["jumlah3"]) * parseFloat(tampung[awal - 1]["harga_jual_member3"])) +
                                                                        (parseFloat(tampung[awal - 1]["jumlah4"]) * parseFloat(tampung[awal - 1]["harga_jual_member4"]))
                                                                        ;
                                                                }

                                                                awal++;
                                                            }

                                                            let tampung_diskon = (parseFloat(diskon_persen) / 100) * total;
                                                            setDiskon(tampung_diskon);

                                                            setDataBelanja(tampung);
                                                            setKode("");
                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </Fab>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Stack spacing={2}
                            sx={{
                                marginTop: "10px"
                            }}
                        >
                            <Pagination
                                color="primary"
                                count={jumlah_halaman_produk}
                                page={halaman_produk}
                                onChange={(event, nomor) => {
                                    pindah_halaman_produk(nomor);
                                }}
                            />
                        </Stack>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogCariProduk(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_draft}
                onClose={() => setTampilDialogDraft(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    Daftar Draft
                </DialogTitle>
                <DialogContent>
                    <Card
                        sx={{
                            backgroundColor: "white",
                            padding: "10px"
                        }}
                    >
                        <TableContainer component={Paper}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Nama Pembeli</StyledTableCell>
                                        <StyledTableCell align="center">Metode Bayar 1</StyledTableCell>
                                        <StyledTableCell align="center">Metode Bayar 2</StyledTableCell>
                                        <StyledTableCell align="center">Aksi</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data_draft.map((tampung_draft) => {
                                        return (
                                            <StyledTableRow key={tampung_draft.id_draft}>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_draft.nama_pelanggan !== "" ? tampung_draft.nama_pelanggan : "Umum"}</Typography></TableCell>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_draft.metode_bayar}</Typography></TableCell>
                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_draft.metode_bayar2}</Typography></TableCell>
                                                <TableCell size="small" align="center">
                                                    <Fab
                                                        color="primary"
                                                        size="small"
                                                        style={{ backgroundColor: "gray", marginRight: "5px" }}
                                                        onClick={() => {
                                                            setIdDraft(tampung_draft.id_draft);
                                                            setMetodeBayar(tampung_draft.id_metode_bayar);

                                                            if (tampung_draft.id_metode_bayar2 !== "0") {
                                                                setJumlahMetodeBayar(2);
                                                                setMetodeBayar2(tampung_draft.id_metode_bayar2);
                                                            }
                                                            else {
                                                                setJumlahMetodeBayar(1);
                                                                setMetodeBayar2("");
                                                            }

                                                            if (tampung_draft.id_sales !== "0") {
                                                                setStatusSales(true);
                                                                setLabelStatusSales("Ada");

                                                                let tampung = [...data_sales];
                                                                let awal = 1;
                                                                let akhir = tampung.length;
                                                                while (awal <= akhir) {
                                                                    if (tampung_draft.id_sales === tampung[awal - 1]["id_pengguna"]) {
                                                                        setSales(tampung[awal - 1]);
                                                                        break;
                                                                    }
                                                                    awal++;
                                                                }

                                                                setMobil(tampung_draft.mobil);
                                                            }
                                                            else {
                                                                setStatusSales(false);
                                                                setLabelStatusSales("Tidak ada");
                                                                setSales(null);
                                                                setMobil("");
                                                            }

                                                            if (tampung_draft.id_pelanggan !== "0") {
                                                                setStatusPembeli(true);
                                                                setLabelStatusPembeli("Pelanggan");

                                                                let tampung = [...data_pelanggan];
                                                                let awal = 1;
                                                                let akhir = tampung.length;
                                                                while (awal <= akhir) {
                                                                    if (tampung_draft.id_pelanggan === tampung[awal - 1]["id_pelanggan"]) {
                                                                        setPelanggan(tampung[awal - 1]);
                                                                        break;
                                                                    }
                                                                    awal++;
                                                                }
                                                            }
                                                            else {
                                                                setStatusPembeli(false);
                                                                setLabelStatusPembeli("Umum");
                                                                setPelanggan(null);
                                                            }

                                                            setMetodeDiskon(tampung_draft.metode_diskon);
                                                            setDiskonPersen(parseFloat(tampung_draft.diskon_persen));
                                                            setDiskon(parseFloat(tampung_draft.diskon));

                                                            fetch(props.aplikasi + '/penjualan/tampil_draft_detail.php',
                                                                {
                                                                    method: 'POST',
                                                                    body: JSON.stringify
                                                                        ({
                                                                            id_draft: tampung_draft.id_draft
                                                                        }),
                                                                    headers:
                                                                    {
                                                                        "Content-type": "application/json; charset=UTF-8"
                                                                    }
                                                                })
                                                                .then(response => response.json())
                                                                .then(data => {
                                                                    if (data.kode === 1) {
                                                                        setDataBelanja(data.data);
                                                                        setTampilDialogDraft(false);
                                                                    }
                                                                    else {
                                                                        setDataBelanja([]);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    setDataBelanja([]);
                                                                }
                                                                );
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </Fab>

                                                    <Fab
                                                        color="primary"
                                                        size="small"
                                                        style={{ backgroundColor: "red" }}
                                                        onClick={() => {
                                                            setIdDraftHapus(tampung_draft.id_draft);
                                                            setTampilDialogHapusDraft(true);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </Fab>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Stack spacing={2}
                            sx={{
                                marginTop: "10px"
                            }}
                        >
                            <Pagination
                                color="primary"
                                count={jumlah_halaman_draft}
                                page={halaman_draft}
                                onChange={(event, nomor) => {
                                    pindah_halaman_draft(nomor);
                                }}
                            />
                        </Stack>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogDraft(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus_draft}
                onClose={() => setTampilDialogHapusDraft(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Draft
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus draft ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogHapusDraft(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setProses(true);
                            fetch(props.aplikasi + '/penjualan/hapus_draft.php',
                                {
                                    method: 'POST',
                                    body: JSON.stringify
                                        ({
                                            id_draft: id_draft_hapus
                                        }),
                                    headers:
                                    {
                                        "Content-type": "application/json; charset=UTF-8"
                                    }
                                })
                                .then(response => response.json())
                                .then(data => {
                                    if (data.kode === 1) {
                                        setJenisNotif("success");
                                        setIsiNotif(data.pesan);
                                        setTampilNotif(true);
                                        setTampilDialogHapusDraft(false);
                                        setTampilDialogDraft(false);
                                        setProses(false);
                                    }
                                    else {
                                        setJenisNotif("warning");
                                        setIsiNotif(data.pesan);
                                        setTampilNotif(true);
                                        setProses(false);
                                    }
                                })
                                .catch(error => {
                                    setJenisNotif("error");
                                    setIsiNotif("Terjadi kesalahan");
                                    setTampilNotif(true);
                                    setProses(false);
                                }
                                );
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_batal}
                onClose={() => setTampilDialogBatal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Batal Transaksi
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin membatalkan transaksi ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogBatal(false)}
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogBatal(false);
                            batal();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_bayar}
                maxWidth="md"
            >
                <DialogTitle>Pembayaran</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" align="right">
                                            Tagihan
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h4" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(tampil_total() - parseFloat(diskon) + tampil_pembulatan())}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="jumlah_bayar"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            disabled={metode_bayar === "99" ? true : false}
                                            value={jumlah_bayar}
                                            onChange={(e) => ubah_jumlah_bayar(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item
                                display={jumlah_metode_bayar === 2 ? "" : "none"}
                            >
                                <Grid item xs={12} sm={12} md={3}>
                                    <Typography variant="body2">
                                        Jumlah Bayar Kedua
                                    </Typography>
                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="jumlah_bayar2"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            disabled={metode_bayar2 === "99" ? true : false}
                                            value={jumlah_bayar2}
                                            onChange={(e) => ubah_jumlah_bayar2(e)}
                                            onBlur={(e) => perbaiki_jumlah_bayar2(e)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "green" }}>
                                            Rp. {format_rupiah(parseFloat(jumlah_bayar2))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={12} md={3}>

                                </Grid>

                                <Grid container item spacing={1} xs={12} sm={12} md={9}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" align="right">
                                            Kembalian
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h6" align="right" sx={{ color: "red" }}>
                                            Rp. {format_rupiah(tampil_kembalian())}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        variant="contained"
                        color="primary"
                        startIcon={<PrintIcon />}
                        sx={
                            id_penjualan !== ""
                                ? { marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }
                                : { display: "none" }
                        }
                        onClick={() =>
                            window.open(props.aplikasi + '/penjualan/cetak_struk.php?id_penjualan=' + id_penjualan)
                        }
                    >
                        Cetak
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        variant="contained"
                        color="primary"
                        startIcon={<AttachMoneyIcon />}
                        sx={
                            id_penjualan === ""
                                ? { marginRight: "5px", marginTop: "5px", borderRadius: "5em" }
                                : { display: "none" }
                        }
                        onClick={() => simpan()}
                    >
                        Bayar
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        variant="contained"
                        color="primary"
                        startIcon={<CloseIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => {
                            if (id_penjualan !== "") {
                                batal();
                            }
                            setTampilDialogBayar(false);
                        }}
                    >
                        Tutup
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Card
                                    sx={{
                                        backgroundColor: "white",
                                        padding: "10px"
                                    }}
                                >
                                    <TextField
                                        id="cari"
                                        label="Scan barcode disini atau klik icon disisi kanan untuk melakukan pencarian"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={kode}
                                        onChange={(event) => setKode(event.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        onClick={() => {
                                                            fetch(props.aplikasi + '/penjualan/tampil_produk_limit.php',
                                                                {
                                                                    method: 'POST',
                                                                    body: JSON.stringify
                                                                        ({
                                                                            id_cabang: localStorage.getItem("id_cabang"),
                                                                            status: "1",
                                                                            cari: "",
                                                                            jumlah_baris: jumlah_baris_produk,
                                                                            halaman: 1
                                                                        }),
                                                                    headers:
                                                                    {
                                                                        "Content-type": "application/json; charset=UTF-8"
                                                                    }
                                                                })
                                                                .then(response => response.json())
                                                                .then(data => {
                                                                    if (data.kode === 1) {
                                                                        setDataProduk(data.data);
                                                                        setJumlahHalamanProduk(Math.ceil(data.jumlah_data / jumlah_baris_produk));
                                                                        setHalamanProduk(1);
                                                                        setPencarian("");
                                                                        setTampilDialogCariProduk(true);
                                                                    }
                                                                    else {
                                                                        setDataProduk([]);
                                                                        setJumlahHalamanProduk(0);
                                                                        setHalamanProduk(0);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    setDataProduk([]);
                                                                    setJumlahHalamanProduk(0);
                                                                    setHalamanProduk(0);
                                                                }
                                                                );
                                                        }}
                                                    >
                                                        <SearchOutlined sx={{ color: "#219cd9" }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                cari();
                                            }
                                        }}
                                    />
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Card
                                    sx={{
                                        backgroundColor: "white",
                                        padding: "10px",
                                        display: "flex",
                                        overflowY: "auto",
                                        maxHeight: "calc(100vh - 165px)"
                                    }}
                                >
                                    <TableContainer id="tabel-belanja" component={Paper}>
                                        <Table stickyHeader size="small" aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">Nama</StyledTableCell>
                                                    <StyledTableCell align="center">Harga</StyledTableCell>
                                                    <StyledTableCell align="center">Diskon</StyledTableCell>
                                                    <StyledTableCell align="center">Jumlah</StyledTableCell>
                                                    <StyledTableCell align="center">Subtotal</StyledTableCell>
                                                    <StyledTableCell align="center">Aksi</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data_belanja.map((tampung_belanja) => {
                                                    return (
                                                        <StyledTableRow key={tampung_belanja.id_produk}>
                                                            <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_belanja.nama}</Typography></TableCell>
                                                            <TableCell size="small" align="right">
                                                                <Link
                                                                    component="button"
                                                                    onClick={() => {
                                                                        setTambahNama(tampung_belanja.nama);
                                                                        setTambahHargaJual1(tampung_belanja.harga_jual1);
                                                                        setTambahSatuan1(tampung_belanja.satuan1);
                                                                        setTambahIdSatuan2(tampung_belanja.id_satuan2);
                                                                        setTambahHargaJual2(tampung_belanja.harga_jual2);
                                                                        setTambahSatuan2(tampung_belanja.satuan2);
                                                                        setTambahPecahan2(tampung_belanja.pecahan2);
                                                                        setTambahIdSatuan3(tampung_belanja.id_satuan3);
                                                                        setTambahHargaJual3(tampung_belanja.harga_jual3);
                                                                        setTambahSatuan3(tampung_belanja.satuan3);
                                                                        setTambahPecahan3(tampung_belanja.pecahan3);
                                                                        setTambahidSatuan4(tampung_belanja.id_satuan4);
                                                                        setTambahHargaJual4(tampung_belanja.harga_jual4);
                                                                        setTambahSatuan4(tampung_belanja.satuan4);
                                                                        setTambahPecahan4(tampung_belanja.pecahan4);
                                                                        setTampilDialogHarga(true);
                                                                    }}
                                                                >
                                                                    {"Rp. " + format_rupiah(parseFloat(tampung_belanja.harga_jual1))}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>
                                                                <Link
                                                                    component="button"
                                                                    sx={{
                                                                        color: "red"
                                                                    }}
                                                                    onClick={() => {
                                                                        setTambahNama(tampung_belanja.nama);
                                                                        setTambahHargaJual1(tampung_belanja.harga_jual1);
                                                                        setTambahHargaJualMember1(tampung_belanja.harga_jual_member1);
                                                                        setTambahSatuan1(tampung_belanja.satuan1);
                                                                        setTambahIdSatuan2(tampung_belanja.id_satuan2);
                                                                        setTambahHargaJual2(tampung_belanja.harga_jual2);
                                                                        setTambahHargaJualMember2(tampung_belanja.harga_jual_member2);
                                                                        setTambahSatuan2(tampung_belanja.satuan2);
                                                                        setTambahPecahan2(tampung_belanja.pecahan2);
                                                                        setTambahIdSatuan3(tampung_belanja.id_satuan3);
                                                                        setTambahHargaJual3(tampung_belanja.harga_jual3);
                                                                        setTambahHargaJualMember3(tampung_belanja.harga_jual_member3);
                                                                        setTambahSatuan3(tampung_belanja.satuan3);
                                                                        setTambahPecahan3(tampung_belanja.pecahan3);
                                                                        setTambahidSatuan4(tampung_belanja.id_satuan4);
                                                                        setTambahHargaJual4(tampung_belanja.harga_jual4);
                                                                        setTambahHargaJualMember4(tampung_belanja.harga_jual_member4);
                                                                        setTambahSatuan4(tampung_belanja.satuan4);
                                                                        setTambahPecahan4(tampung_belanja.pecahan4);
                                                                        setTampilDialogDiskonMember(true);
                                                                    }}
                                                                >
                                                                    Rp. {status_pembeli === false ?
                                                                        format_rupiah(0)
                                                                        :
                                                                        format_rupiah(parseFloat(tampung_belanja.harga_jual1) - parseFloat(tampung_belanja.harga_jual_member1))
                                                                    }
                                                                </Link>
                                                            </Typography></TableCell>
                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => kurang_belanja(tampung_belanja.id_produk)}
                                                                >
                                                                    <RemoveIcon />
                                                                </Fab>
                                                                &nbsp;&nbsp;
                                                                <Link
                                                                    component="button"
                                                                    onClick={() => {
                                                                        setTambahIdProduk(tampung_belanja.id_produk);
                                                                        setTambahNama(tampung_belanja.nama);
                                                                        setTambahJumlah1(parseFloat(tampung_belanja.jumlah1));
                                                                        setTambahStok1(parseFloat(tampung_belanja.stok1));
                                                                        setTambahSatuan1(tampung_belanja.satuan1);
                                                                        setTambahIdSatuan2(tampung_belanja.id_satuan2);
                                                                        setTambahJumlah2(parseFloat(tampung_belanja.jumlah2));
                                                                        setTambahStok2(parseFloat(tampung_belanja.stok2));
                                                                        setTambahSatuan2(tampung_belanja.satuan2);
                                                                        setTambahPecahan2(parseFloat(tampung_belanja.pecahan2));
                                                                        setTambahIdSatuan3(tampung_belanja.id_satuan3);
                                                                        setTambahJumlah3(parseFloat(tampung_belanja.jumlah3));
                                                                        setTambahStok3(parseFloat(tampung_belanja.stok3));
                                                                        setTambahSatuan3(tampung_belanja.satuan3);
                                                                        setTambahPecahan3(parseFloat(tampung_belanja.pecahan3));
                                                                        setTambahidSatuan4(tampung_belanja.id_satuan4);
                                                                        setTambahJumlah4(parseFloat(tampung_belanja.jumlah4));
                                                                        setTambahStok4(parseFloat(tampung_belanja.stok4));
                                                                        setTambahSatuan4(tampung_belanja.satuan4);
                                                                        setTambahPecahan4(parseFloat(tampung_belanja.pecahan4));
                                                                        setTampilDialogTambah(true);
                                                                    }}
                                                                >
                                                                    {tampil_jumlah(
                                                                        tampung_belanja.jumlah1,
                                                                        tampung_belanja.satuan1,
                                                                        tampung_belanja.jumlah2,
                                                                        tampung_belanja.satuan2,
                                                                        tampung_belanja.jumlah3,
                                                                        tampung_belanja.satuan3,
                                                                        tampung_belanja.jumlah4,
                                                                        tampung_belanja.satuan4
                                                                    )}
                                                                </Link>
                                                                &nbsp;&nbsp;
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => tambah_belanja(tampung_belanja.id_produk)}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </TableCell>
                                                            <TableCell size="small" align="right">
                                                                <Typography variant="body2">
                                                                    Rp. {format_rupiah(tampil_subtotal(
                                                                        tampung_belanja.jumlah1,
                                                                        tampung_belanja.jumlah2,
                                                                        tampung_belanja.jumlah3,
                                                                        tampung_belanja.jumlah4,
                                                                        tampung_belanja.harga_jual1,
                                                                        tampung_belanja.harga_jual_member1,
                                                                        tampung_belanja.harga_jual2,
                                                                        tampung_belanja.harga_jual_member2,
                                                                        tampung_belanja.harga_jual3,
                                                                        tampung_belanja.harga_jual_member3,
                                                                        tampung_belanja.harga_jual4,
                                                                        tampung_belanja.harga_jual_member4
                                                                    ))}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell size="small" align="center">
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "red" }}
                                                                    onClick={() => hapus_belanja(tampung_belanja.id_produk)}
                                                                >
                                                                    <DeleteIcon />
                                                                </Fab>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Card
                                            sx={{
                                                backgroundColor: "white",
                                                padding: "10px"
                                            }}
                                        >
                                            <Grid container item spacing={1}>
                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>

                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <LoadingButton
                                                            loadingPosition="start"
                                                            loading={proses}
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            startIcon={<ListIcon />}
                                                            sx={{ marginRight: "5px", marginBottom: "10px", borderRadius: "5em", backgroundColor: "#ff93b7" }}
                                                            onClick={() => {
                                                                fetch(props.aplikasi + '/penjualan/tampil_draft_limit.php',
                                                                    {
                                                                        method: 'POST',
                                                                        body: JSON.stringify
                                                                            ({
                                                                                id_cabang: localStorage.getItem("id_cabang"),
                                                                                jumlah_baris: jumlah_baris_draft,
                                                                                halaman: 1
                                                                            }),
                                                                        headers:
                                                                        {
                                                                            "Content-type": "application/json; charset=UTF-8"
                                                                        }
                                                                    })
                                                                    .then(response => response.json())
                                                                    .then(data => {
                                                                        if (data.kode === 1) {
                                                                            setDataDraft(data.data);
                                                                            setJumlahHalamanDraft(Math.ceil(data.jumlah_data / jumlah_baris_draft));
                                                                            setHalamanDraft(1);
                                                                            setTampilDialogDraft(true)
                                                                        }
                                                                        else {
                                                                            setDataDraft([]);
                                                                            setJumlahHalamanDraft(0);
                                                                            setHalamanDraft(0);
                                                                        }
                                                                    })
                                                                    .catch(error => {
                                                                        setDataDraft([]);
                                                                        setJumlahHalamanDraft(0);
                                                                        setHalamanDraft(0);
                                                                    }
                                                                    );
                                                            }}
                                                        >
                                                            <Typography component={"span"} variant="h6" noWrap={true}>Daftar Draft</Typography>
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Status Sales
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={status_sales}
                                                                    onChange={(event) => {
                                                                        if (status_sales === false) {
                                                                            setStatusSales(true);
                                                                            setLabelStatusSales("Tidak ada");
                                                                        }
                                                                        else {
                                                                            setStatusSales(false);
                                                                            setLabelStatusSales("Ada");
                                                                            setSales(null);
                                                                            setMobil("");
                                                                        }
                                                                    }}
                                                                />
                                                            }

                                                            label={label_status_sales}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item display={status_sales === false ? "none" : ""}>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Sales
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Autocomplete
                                                            options={data_sales}
                                                            getOptionLabel={
                                                                (option) => option.nama + " (" + option.no_telpon + ")"
                                                            }
                                                            value={sales}
                                                            onChange={(e, nilai) => setSales(nilai)}
                                                            renderInput={
                                                                (params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        key={params.id_sales}
                                                                        variant="outlined"
                                                                        size="small"
                                                                    />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item display={status_sales === false ? "none" : ""}>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Mobil
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            value={mobil}
                                                            onChange={(event) => setMobil(event.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Status Pembeli
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={status_pembeli}
                                                                    onChange={(event) => {
                                                                        if (status_pembeli === false) {
                                                                            setStatusPembeli(true);
                                                                            setLabelStatusPembeli("Pelanggan");

                                                                            if (metode_diskon === "persen") {
                                                                                let total = 0;
                                                                                let tampung = [...data_belanja];
                                                                                let awal = 1;
                                                                                let akhir = tampung.length;
                                                                                while (awal <= akhir) {
                                                                                    let vartotaltambah = parseFloat(tampung[awal - 1]["jumlah1"]);

                                                                                    if (parseFloat(tampung[awal - 1]["pecahan2"]) > 0) {
                                                                                        vartotaltambah = vartotaltambah + (parseFloat(tampung[awal - 1]["jumlah2"]) / parseFloat(tampung[awal - 1]["pecahan2"]));
                                                                                    }

                                                                                    if (parseFloat(tampung[awal - 1]["pecahan3"]) > 0) {
                                                                                        vartotaltambah = vartotaltambah + (parseFloat(tampung[awal - 1]["jumlah3"]) / parseFloat(tampung[awal - 1]["pecahan3"]) / parseFloat(tampung[awal - 1]["pecahan2"]));
                                                                                    }

                                                                                    if (parseFloat(tampung[awal - 1]["pecahan4"]) > 0) {
                                                                                        vartotaltambah = vartotaltambah + (parseFloat(tampung[awal - 1]["jumlah4"]) / parseFloat(tampung[awal - 1]["pecahan4"]) / parseFloat(tampung[awal - 1]["pecahan3"]) / parseFloat(tampung[awal - 1]["pecahan2"]));
                                                                                    }

                                                                                    total = total + (vartotaltambah * parseFloat(tampung[awal - 1]["harga_jual_member"]));

                                                                                    awal++;
                                                                                }

                                                                                let tampung_diskon = (parseFloat(diskon_persen) / 100) * total;
                                                                                setDiskon(tampung_diskon);
                                                                            }
                                                                        }
                                                                        else {
                                                                            setStatusPembeli(false);
                                                                            setLabelStatusPembeli("Umum");
                                                                            setPelanggan(null);

                                                                            if (metode_diskon === "persen") {
                                                                                let total = 0;
                                                                                let tampung = [...data_belanja];
                                                                                let awal = 1;
                                                                                let akhir = tampung.length;
                                                                                while (awal <= akhir) {
                                                                                    let vartotaltambah = parseFloat(tampung[awal - 1]["jumlah1"]);

                                                                                    if (parseFloat(tampung[awal - 1]["pecahan2"]) > 0) {
                                                                                        vartotaltambah = vartotaltambah + (parseFloat(tampung[awal - 1]["jumlah2"]) / parseFloat(tampung[awal - 1]["pecahan2"]));
                                                                                    }

                                                                                    if (parseFloat(tampung[awal - 1]["pecahan3"]) > 0) {
                                                                                        vartotaltambah = vartotaltambah + (parseFloat(tampung[awal - 1]["jumlah3"]) / parseFloat(tampung[awal - 1]["pecahan3"]) / parseFloat(tampung[awal - 1]["pecahan2"]));
                                                                                    }

                                                                                    if (parseFloat(tampung[awal - 1]["pecahan4"]) > 0) {
                                                                                        vartotaltambah = vartotaltambah + (parseFloat(tampung[awal - 1]["jumlah4"]) / parseFloat(tampung[awal - 1]["pecahan4"]) / parseFloat(tampung[awal - 1]["pecahan3"]) / parseFloat(tampung[awal - 1]["pecahan2"]));
                                                                                    }

                                                                                    total = total + (vartotaltambah * parseFloat(tampung[awal - 1]["harga_jual"]));

                                                                                    awal++;
                                                                                }

                                                                                let tampung_diskon = (parseFloat(diskon_persen) / 100) * total;
                                                                                setDiskon(tampung_diskon);
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            }

                                                            label={label_status_pembeli}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item display={status_pembeli === false ? "none" : ""}>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Pelanggan
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Autocomplete
                                                            options={data_pelanggan}
                                                            getOptionLabel={
                                                                (option) => option.nama + " (" + option.no_telpon + ")"
                                                            }
                                                            value={pelanggan}
                                                            onChange={(e, nilai) => setPelanggan(nilai)}
                                                            renderInput={
                                                                (params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        key={params.id_pelanggan}
                                                                        variant="outlined"
                                                                        size="small"
                                                                    />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Metode Bayar
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <FormControl size="small" fullWidth>
                                                            <Select
                                                                id="metode_bayar"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={metode_bayar}
                                                                onChange={(event) => {
                                                                    if (jumlah_metode_bayar === 2) {
                                                                        if (event.target.value === metode_bayar2) {
                                                                            setJenisNotif("warning");
                                                                            setIsiNotif("Metode bayar harus berbeda");
                                                                            setTampilNotif(true);
                                                                            return;
                                                                        }
                                                                    }

                                                                    setMetodeBayar(event.target.value);
                                                                }}
                                                            >
                                                                {data_metode_bayar.map((tampung_metode_bayar) => {
                                                                    return (
                                                                        <MenuItem key={tampung_metode_bayar.id_metode} value={tampung_metode_bayar.id_metode}>{tampung_metode_bayar.nama}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item>
                                                    <Grid item xs={12} sm={4} md={3}>

                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <Link
                                                            component="button"
                                                            display={jumlah_metode_bayar === 1 ? "" : "none"}
                                                            onClick={() => {
                                                                let awal = 1;
                                                                let akhir = data_metode_bayar.length;
                                                                while (awal <= akhir) {
                                                                    if (data_metode_bayar[awal - 1]["id_metode"] === metode_bayar) {
                                                                        if (parseFloat(data_metode_bayar[awal - 1]["tambah_hpp"]) > 0 || parseFloat(data_metode_bayar[awal - 1]["tambah_jual"]) > 0) {
                                                                            setJenisNotif("warning");
                                                                            setIsiNotif("Tidak dapat menambah metode bayar karena terdapat metode bayar dengan penambahan nilai jual");
                                                                            setTampilNotif(true);
                                                                            return;
                                                                        }
                                                                        break;
                                                                    }
                                                                    awal++;
                                                                }

                                                                if (jumlah_metode_bayar === 1) {
                                                                    setJumlahMetodeBayar(2);
                                                                }
                                                                else {
                                                                    setJumlahMetodeBayar(1);
                                                                }
                                                            }}
                                                        >
                                                            Tambah metode bayar
                                                        </Link>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item display={jumlah_metode_bayar === 1 ? "none" : ""}>
                                                    <Grid item xs={12} sm={4} md={3}>
                                                        <Typography variant="body2">
                                                            Metode Bayar Kedua
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9}>
                                                        <FormControl size="small" fullWidth>
                                                            <Select
                                                                id="metode_bayar2"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={metode_bayar2}
                                                                onChange={(event) => {
                                                                    if (event.target.value === metode_bayar) {
                                                                        setJenisNotif("warning");
                                                                        setIsiNotif("Metode bayar harus berbeda");
                                                                        setTampilNotif(true);
                                                                        return;
                                                                    }

                                                                    setMetodeBayar2(event.target.value);
                                                                }}
                                                            >
                                                                <MenuItem value="">Pilih Metode Bayar Kedua</MenuItem>
                                                                {data_metode_bayar.map((tampung_metode_bayar) => {
                                                                    return (
                                                                        <MenuItem key={tampung_metode_bayar.id_metode} value={tampung_metode_bayar.id_metode}>{tampung_metode_bayar.nama}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>

                                                        <Link
                                                            component="button"
                                                            display={jumlah_metode_bayar === 1 ? "none" : ""}
                                                            onClick={() => {
                                                                if (jumlah_metode_bayar === 1) {
                                                                    setJumlahMetodeBayar(2);
                                                                }
                                                                else {
                                                                    setJumlahMetodeBayar(1);
                                                                }

                                                                setMetodeBayar2("");
                                                            }}
                                                        >
                                                            Hapus metode bayar
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Card
                                            sx={{
                                                backgroundColor: "white",
                                                padding: "10px",
                                            }}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Total</Typography>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "green" }}>{"Rp. " + format_rupiah(tampil_total())}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4} sm={8}>
                                                    <Typography variant="h6" sx={{ display: "inline", marginRight: "10px" }}>Diskon</Typography>

                                                    <IconButton
                                                        onClick={(event) => setTampilDialogDiskon(true)}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: "tomato"
                                                        }}
                                                    >
                                                        <EditIcon
                                                            sx={{
                                                                color: "white"
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Grid>

                                                <Grid item xs={8} sm={4}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "green" }}>
                                                        {diskon > 0 ? "-" + format_rupiah(parseFloat(diskon)) : format_rupiah(parseFloat(diskon))}
                                                    </Typography>

                                                    {metode_diskon === "persen" ?
                                                        <Typography variant="body1" align="right" noWrap={true} sx={{ color: "tomato" }}>
                                                            ({diskon_persen > 0 ? format_rupiah(parseFloat(diskon_persen)) : 0}%)
                                                        </Typography>
                                                        :
                                                        <></>
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Divider
                                                        sx={{
                                                            marginTop: "10px"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4} sm={8}>

                                                </Grid>

                                                <Grid item xs={8} sm={4}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "tomato" }}>{format_rupiah(parseFloat(tampil_total()) - parseFloat(diskon))}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Pembulatan</Typography>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Typography variant="h6" align="right" noWrap={true} sx={{ color: "green" }}>{format_rupiah(tampil_pembulatan())}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={4}>
                                                    <Typography variant="h6">Grand Total</Typography>
                                                </Grid>

                                                <Grid item xs={8}>
                                                    <Typography variant="h4" align="right" noWrap={true} sx={{ color: "red" }}>{format_rupiah(tampil_total() - parseFloat(diskon) + tampil_pembulatan())}</Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent="center">
                                                <LoadingButton
                                                    loadingPosition="start"
                                                    loading={proses}
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<AttachMoneyIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em" }}
                                                    onClick={() => {
                                                        if (data_belanja.length > 0) {
                                                            if (jumlah_metode_bayar === 2 && metode_bayar2 === "") {
                                                                setJenisNotif("warning");
                                                                setIsiNotif("Metode bayar kedua belum diisi");
                                                                setTampilNotif(true);
                                                                return;
                                                            }

                                                            setJumlahBayar(0);
                                                            setJumlahBayar2(0);
                                                            setTampilDialogBayar(true);
                                                        }
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Bayar</Typography>
                                                </LoadingButton>

                                                <LoadingButton
                                                    loadingPosition="start"
                                                    loading={proses}
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<SaveIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em", backgroundColor: "#37ce9b" }}
                                                    onClick={() => {
                                                        simpan_draft();
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Draft</Typography>
                                                </LoadingButton>

                                                <LoadingButton
                                                    loadingPosition="start"
                                                    loading={proses} on
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<CachedIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em", backgroundColor: "tomato" }}
                                                    onClick={() => {
                                                        if (data_belanja.length > 0) {
                                                            setTampilDialogBatal(true);
                                                        }
                                                        else {
                                                            batal();
                                                        }
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Batal</Typography>
                                                </LoadingButton>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default forwardRef(Penjualan);