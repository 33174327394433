// core
import React, { useState, useEffect, forwardRef } from 'react';

// 3rd
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';

// aset
import PrintIcon from '@mui/icons-material/Print';
import BorderAllIcon from '@mui/icons-material/BorderAll';

function Tracking_GL(props, ref) {
    // state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [periode1, setPeriode1] = useState(new Date());
    const [periode2, setPeriode2] = useState(new Date());
    const [data_gl, setDataGl] = useState([]);
    const [gl, setGl] = useState(null);

    // fungsi    
    useEffect(() => {
        fetch(props.aplikasi + '/akuntansi/tampil_gl.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataGl(data.data);
                }
                else {
                    setDataGl([]);
                }
            })
            .catch(error => {
                setDataGl([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const cetak = () => {
        if (periode1 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode2 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (gl === null) {
            setJenisNotif("warning");
            setIsiNotif("Akun GL wajib dipilih");
            setTampilNotif(true);
            document.getElementById("gl").focus();
            return;
        }

        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/akuntansi/cetak_tracking.php?id_cabang=' + localStorage.getItem("id_cabang") + '&periode1=' + format_periode1 + '&periode2=' + format_periode2 + '&gl=' + gl.id_gl);
    }

    const excel = () => {
        if (periode1 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode2 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (gl === null) {
            setJenisNotif("warning");
            setIsiNotif("Akun GL wajib dipilih");
            setTampilNotif(true);
            document.getElementById("gl").focus();
            return;
        }

        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/akuntansi/excel_tracking.php?id_cabang=' + localStorage.getItem("id_cabang") + '&periode1=' + format_periode1 + '&periode2=' + format_periode2 + '&gl=' + gl.id_gl);
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Akun GL
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Autocomplete
                                    id="gl"
                                    options={data_gl}
                                    getOptionLabel={(option) => option.id_gl + " - " + option.gl}
                                    value={gl}
                                    onChange={(e, nilai) => setGl(nilai)}
                                    renderInput={(params) => <TextField {...params} key={params.id_gl} variant="outlined" size="small" />}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Tanggal Transaksi
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode1}
                                        onChange={(newValue) => {
                                            setPeriode1(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} size='small' />}
                                    />
                                </LocalizationProvider>

                                <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                    s/d
                                </Typography>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode2}
                                        onChange={(newValue) => {
                                            setPeriode2(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} size='small' />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<BorderAllIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "green" }}
                                    onClick={() => excel()}
                                >
                                    <Typography variant="body2" noWrap={true}>Excel</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default forwardRef(Tracking_GL);