import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function Open_Close(props, ref) {
    //state
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Closing");

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pengaturan/tampil_pengaturan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    if (data.data[0].status_opening === "1") {
                        setStatus(true);
                        setLabelStatus("Opening");
                    }
                    else {
                        setStatus(false);
                        setLabelStatus("Closing");
                    }
                }
                else {
                    setStatus(false);
                    setLabelStatus("Closing");
                }
            })
            .catch(error => {
                setStatus(false);
                setLabelStatus("Closing");
            }
            );
    }, [props.aplikasi]);           

    return (
        <div>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={12} sm={4} md={3} >
                                <Typography variant="body2">
                                    Buka / Tutup Transaksi
                                </Typography    >
                            </Grid>

                            <Grid item xs={12} sm={8} md={9}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={status}
                                            onChange={(event) => {                                                    
                                                if (status === false) {
                                                    setStatus(true);
                                                    setLabelStatus("Opening");
                                                }
                                                else {
                                                    setStatus(false);
                                                    setLabelStatus("Closing");
                                                }

                                                fetch(props.aplikasi + '/pengaturan/simpan_open_close.php',
                                                    {
                                                        method: 'POST',
                                                        body: JSON.stringify
                                                            ({
                                                                status: event.target.checked                                                                
                                                            }),
                                                        headers:
                                                        {
                                                            "Content-type": "application/json; charset=UTF-8"
                                                        }
                                                    })
                                                    .then(response => response.json())
                                                    .then(data => {
                                                        if (data.kode === 1) {
                                                            
                                                        }
                                                        else {
                                                            
                                                        }
                                                    })
                                                    .catch(error => {
                                                        
                                                    }
                                                    );
                                            }}
                                        />
                                    }

                                    label={label_status}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
}

export default forwardRef(Open_Close);