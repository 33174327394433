// core
import React, { useState, useEffect, forwardRef } from 'react';

// 3rd
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

// aset
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

function Produk(props, ref) {
    // state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [data_produk, setDataProduk] = useState([]);
    const [total, setTotal] = useState(0);
    const [pencarian, setPencarian] = useState("");
    const [data_jenis, setDataJenis] = useState([]);
    const [jenis, setJenis] = useState(null);
    const [data_supplier, setDataSupplier] = useState([]);
    const [supplier, setSupplier] = useState(null);
    const [data_satuan, setDataSatuan] = useState([]);
    const [satuan1, setSatuan1] = useState(null);
    const [harga_beli1, setHargaBeli1] = useState(0);
    const [harga_jual1, setHargaJual1] = useState(0);
    const [harga_jual_member1, setHargaJualMember1] = useState(0);
    const [satuan2, setSatuan2] = useState(null);
    const [harga_beli2, setHargaBeli2] = useState(0);
    const [harga_jual2, setHargaJual2] = useState(0);
    const [harga_jual_member2, setHargaJualMember2] = useState(0);
    const [pecahan2, setPecahan2] = useState(0);
    const [satuan3, setSatuan3] = useState(null);
    const [harga_beli3, setHargaBeli3] = useState(0);
    const [harga_jual3, setHargaJual3] = useState(0);
    const [harga_jual_member3, setHargaJualMember3] = useState(0);
    const [pecahan3, setPecahan3] = useState(0);
    const [satuan4, setSatuan4] = useState(null);
    const [harga_beli4, setHargaBeli4] = useState(0);
    const [harga_jual4, setHargaJual4] = useState(0);
    const [harga_jual_member4, setHargaJualMember4] = useState(0);
    const [pecahan4, setPecahan4] = useState(0);
    const [id_supplier, setIdSupplier] = useState("");
    const [id_produk, setIdProduk] = useState("");
    const [nama, setNama] = useState("");
    const [kode, setKode] = useState("");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [tampil_dialog_cetak, setTampilDialogCetak] = useState(false);
    const [jumlah_cetak, setJumlahCetak] = useState(1);
    const [jumlah_baris] = useState(36);
    const [jumlah_halaman, setJumlahHalaman] = useState(0);
    const [halaman, setHalaman] = useState(1);

    // fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/produk/tampil_produk_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        status: "",
                        cari: "",
                        jumlah_baris: jumlah_baris,
                        halaman: 1
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataProduk(data.data);
                    setTotal(data.total);
                    setJumlahHalaman(Math.ceil(data.jumlah_data / 40));
                }
                else {
                    setDataProduk([]);
                    setTotal(0);
                    setJumlahHalaman(0);
                }
            })
            .catch(error => {
                setDataProduk([]);
                setTotal(0);
                setJumlahHalaman(0);
            }
            );

        fetch(props.aplikasi + '/produk/tampil_jenis_produk.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataJenis(data.data);
                }
                else {
                    setDataJenis([]);
                }
            })
            .catch(error => {
                setDataJenis([]);
            }
            );

        fetch(props.aplikasi + '/produk/tampil_satuan.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataSatuan(data.data);
                }
                else {
                    setDataSatuan([]);
                }
            })
            .catch(error => {
                setDataSatuan([]);
            }
            );

        fetch(props.aplikasi + '/produk/tampil_supplier.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataSupplier(data.data);
                }
                else {
                    setDataSupplier([]);
                }
            })
            .catch(error => {
                setDataSupplier([]);
            }
            );
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_id_supplier = (varid_supplier) => {
        while (varid_supplier.length < 4) {
            varid_supplier = "0" + varid_supplier;
        }

        return varid_supplier;
    }

    const tampil_id_produk = () => {
        if (id_produk !== "") {
            let varid_supplier = id_supplier;
            while (varid_supplier.length < 4) {
                varid_supplier = "0" + varid_supplier;
            }

            let varid_produk = id_produk;
            while (varid_produk.length < 4) {
                varid_produk = "0" + varid_produk;
            }

            return varid_supplier + "." + varid_produk;
        }
        else {
            return "";
        }
    }

    const tampil_produk = (halaman_baru) => {
        fetch(props.aplikasi + '/produk/tampil_produk_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        status: "",
                        cari: pencarian,
                        jumlah_baris: jumlah_baris,
                        halaman: halaman_baru
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataProduk(data.data);
                    setTotal(data.total);
                    setJumlahHalaman(Math.ceil(data.jumlah_data / jumlah_baris));
                }
                else {
                    setDataProduk([]);
                    setTotal(0);
                    setJumlahHalaman(0);
                }
            })
            .catch(error => {
                setDataProduk([]);
                setTotal(0);
                setJumlahHalaman(0);
            }
            );
    }

    const pindah_halaman = async (halaman_baru) => {
        setHalaman(halaman_baru);
        tampil_produk(halaman_baru);
    }

    const cari = () => {
        setHalaman(1);
        tampil_produk(1);
    }

    const bersih_input_produk = () => {
        setJenis(null);
        setSupplier(null);
        setSatuan1(null);
        setHargaBeli1(0);
        setHargaJual1(0);
        setHargaJualMember1(0);
        setSatuan2(null);
        setHargaBeli2(0);
        setHargaJual2(0);
        setHargaJualMember2(0);
        setSatuan3(null);
        setHargaBeli3(0);
        setHargaJual3(0);
        setHargaJualMember3(0);
        setSatuan4(null);
        setHargaBeli4(0);
        setHargaJual4(0);
        setHargaJualMember4(0);
        setPecahan2(0);
        setPecahan3(0);
        setPecahan4(0);
        setIdProduk("");
        setNama("");
        setKode("");
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const ubah_satuan = (nilai, varurutan) => {
        if (varurutan === 1) {
            setSatuan1(nilai);

            if (nilai === null || nilai.id_satuan === "0") {
                setHargaBeli1(0);
                setHargaJual1(0);
                setHargaJualMember1(0);
            }
        }
        else if (varurutan === 2) {
            setSatuan2(nilai);

            if (nilai === null || nilai.id_satuan === "0") {
                setHargaBeli2(0);
                setHargaJual2(0);
                setHargaJualMember2(0);
                setPecahan2(0);
            }
        }
        else if (varurutan === 3) {
            setSatuan3(nilai);

            if (nilai === null || nilai.id_satuan === "0") {
                setHargaBeli3(0);
                setHargaJual3(0);
                setHargaJualMember3(0);
                setPecahan3(0);
            }
        }
        else if (varurutan === 4) {
            setSatuan4(nilai);

            if (nilai === null || nilai.id_satuan === "0") {
                setHargaBeli4(0);
                setHargaJual4(0);
                setHargaJualMember4(0);
                setPecahan4(0);
            }
        }
    }

    const ubah_harga_beli = (event, varurutan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        if (varurutan === 1) {
            setHargaBeli1(event.target.value);
        }
        else if (varurutan === 2) {
            setHargaBeli2(event.target.value);
        }
        else if (varurutan === 3) {
            setHargaBeli3(event.target.value);
        }
        else if (varurutan === 4) {
            setHargaBeli4(event.target.value);
        }
    }

    const perbaiki_harga_beli = (event, varurutan) => {
        if (event.target.value.trim() === "") {
            if (varurutan === 1) {
                setHargaBeli1(0);
            }
            else if (varurutan === 2) {
                setHargaBeli1(2);
            }
            else if (varurutan === 3) {
                setHargaBeli3(0);
            }
            else if (varurutan === 4) {
                setHargaBeli4(0);
            }
        }
    }

    const ubah_harga_jual = (event, varurutan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        if (varurutan === 1) {
            setHargaJual1(event.target.value);
        }
        else if (varurutan === 2) {
            setHargaJual2(event.target.value);
        }
        else if (varurutan === 3) {
            setHargaJual3(event.target.value);
        }
        else if (varurutan === 4) {
            setHargaJual4(event.target.value);
        }
    }

    const perbaiki_harga_jual = (event, varurutan) => {
        if (event.target.value.trim() === "") {
            if (varurutan === 1) {
                setHargaJual1(0);
            }
            else if (varurutan === 2) {
                setHargaJual2(2);
            }
            else if (varurutan === 3) {
                setHargaJual3(0);
            }
            else if (varurutan === 4) {
                setHargaJual4(0);
            }
        }
    }

    const ubah_harga_jual_member = (event, varurutan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        if (varurutan === 1) {
            setHargaJualMember1(event.target.value);
        }
        else if (varurutan === 2) {
            setHargaJualMember2(event.target.value);
        }
        else if (varurutan === 3) {
            setHargaJualMember3(event.target.value);
        }
        else if (varurutan === 4) {
            setHargaJualMember4(event.target.value);
        }
    }

    const perbaiki_harga_jual_member = (event, varurutan) => {
        if (event.target.value.trim() === "") {
            if (varurutan === 1) {
                setHargaJualMember1(0);
            }
            else if (varurutan === 2) {
                setHargaJualMember2(0);
            }
            else if (varurutan === 3) {
                setHargaJualMember3(0);
            }
            else if (varurutan === 4) {
                setHargaJualMember4(0);
            }
        }
    }

    const ubah_pecahan = (event, varurutan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        if (varurutan === 2) {
            setPecahan2(event.target.value);
        }
        else if (varurutan === 3) {
            setPecahan3(event.target.value);
        }
        else if (varurutan === 4) {
            setPecahan4(event.target.value);
        }
    }

    const perbaiki_pecahan = (event, varurutan) => {
        if (event.target.value.trim() === "") {
            if (varurutan === 2) {
                setPecahan2(0);
            }
            else if (varurutan === 3) {
                setPecahan3(0);
            }
            else if (varurutan === 4) {
                setPecahan4(0);
            }
        }
    }

    const simpan = () => {
        if (jenis === null) {
            setJenisNotif("warning");
            setIsiNotif("Jenis produk wajib diisi");
            setTampilNotif(true);
            document.getElementById("jenis").focus();
            return;
        }

        if (supplier === null) {
            setJenisNotif("warning");
            setIsiNotif("Supplier wajib diisi");
            setTampilNotif(true);
            document.getElementById("supplier").focus();
            return;
        }

        if (nama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama produk wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (satuan1 === null || satuan1.id_satuan === "0") {
            setJenisNotif("warning");
            setIsiNotif("Satuan 1 produk wajib diisi");
            setTampilNotif(true);
            document.getElementById("satuan1").focus();
            return;
        }

        if (parseFloat(harga_beli1) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Harga pokok penjualan 1 wajib diisi");
            setTampilNotif(true);
            document.getElementById("harga_beli1").focus();
            return;
        }

        if (parseFloat(harga_jual1) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Harga jual 1 wajib diisi");
            setTampilNotif(true);
            document.getElementById("harga1").focus();
            return;
        }

        if (parseFloat(harga_jual_member1) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Harga jual member 1 wajib diisi");
            setTampilNotif(true);
            document.getElementById("harga_jual_member1").focus();
            return;
        } 
        
        if (satuan2 !== null && satuan2.id_satuan !== "0") {
            if (parseFloat(harga_beli2) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Harga pokok penjualan 2 wajib diisi");
                setTampilNotif(true);
                document.getElementById("harga_beli2").focus();
                return;
            }
    
            if (parseFloat(harga_jual2) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Harga jual 2 wajib diisi");
                setTampilNotif(true);
                document.getElementById("harga2").focus();
                return;
            }
    
            if (parseFloat(harga_jual_member2) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Harga jual member 2 wajib diisi");
                setTampilNotif(true);
                document.getElementById("harga_jual_member2").focus();
                return;
            } 

            if (parseFloat(pecahan2) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Pecahan 2 wajib diisi");
                setTampilNotif(true);
                document.getElementById("pecahan2").focus();
                return;
            }
        }

        // else if ((satuan2 === null || satuan2.id_satuan === "0") && parseFloat(pecahan2) > 0) {
        //     setJenisNotif("warning");
        //     setIsiNotif("Satuan 2 tidak bisa diisi");
        //     setTampilNotif(true);
        //     document.getElementById("pecahan2").focus();
        //     return;
        // }

        if ((satuan3 !== null && satuan3.id_satuan !== "0") && (satuan2 === null || satuan2.id_satuan === "0")) {
            setJenisNotif("warning");
            setIsiNotif("Satuan 2 wajib diisi terlebih dahulu");
            setTampilNotif(true);
            document.getElementById("satuan2").focus();
            return;
        }

        if (satuan3 !== null && satuan3.id_satuan !== "0") {
            if (parseFloat(harga_beli3) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Harga pokok penjualan 3 wajib diisi");
                setTampilNotif(true);
                document.getElementById("harga_beli3").focus();
                return;
            }
    
            if (parseFloat(harga_jual3) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Harga jual 3 wajib diisi");
                setTampilNotif(true);
                document.getElementById("harga3").focus();
                return;
            }
    
            if (parseFloat(harga_jual_member3) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Harga jual member 3 wajib diisi");
                setTampilNotif(true);
                document.getElementById("harga_jual_member3").focus();
                return;
            } 

            if (parseFloat(pecahan3) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Pecahan 3 wajib diisi");
                setTampilNotif(true);
                document.getElementById("pecahan3").focus();
                return;
            }
        }

        if ((satuan4 !== null && satuan4.id_satuan !== "0") && (satuan3 === null || satuan3.id_satuan === "0")) {
            setJenisNotif("warning");
            setIsiNotif("Satuan 3 wajib diisi terlebih dahulu");
            setTampilNotif(true);
            document.getElementById("satuan3").focus();
            return;
        }

        if (satuan4 !== null && satuan4.id_satuan !== "0") {
            if (parseFloat(harga_beli4) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Harga pokok penjualan 4 wajib diisi");
                setTampilNotif(true);
                document.getElementById("harga_beli4").focus();
                return;
            }
    
            if (parseFloat(harga_jual4) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Harga jual 4 wajib diisi");
                setTampilNotif(true);
                document.getElementById("harga4").focus();
                return;
            }
    
            if (parseFloat(harga_jual_member4) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Harga jual member 4 wajib diisi");
                setTampilNotif(true);
                document.getElementById("harga_jual_member4").focus();
                return;
            } 

            if (parseFloat(pecahan4) === 0) {
                setJenisNotif("warning");
                setIsiNotif("Pecahan 4 wajib diisi");
                setTampilNotif(true);
                document.getElementById("pecahan4").focus();
                return;
            }
        }

        let varsatuan1 = satuan1.id_satuan;
        let varsatuan2 = "";
        let varsatuan3 = "";
        let varsatuan4 = "";

        if (satuan2 !== null) {
            varsatuan2 = satuan2.id_satuan;
        }

        if (satuan3 !== null) {
            varsatuan3 = satuan3.id_satuan;
        }

        if (satuan4 !== null) {
            varsatuan4 = satuan4.id_satuan;
        }

        setProses(true);
        if (id_produk === "") {
            fetch(props.aplikasi + '/produk/simpan_produk.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_cabang: localStorage.getItem("id_cabang"),
                            id_produk: "",
                            id_jenis: jenis.id_jenis,
                            id_supplier: supplier.id_supplier,
                            nama: nama,
                            kode: kode,
                            satuan1: varsatuan1,
                            harga_beli1: harga_beli1,
                            harga_jual1: harga_jual1,
                            harga_jual_member1: harga_jual_member1,
                            satuan2: varsatuan2,
                            harga_beli2: harga_beli2,
                            harga_jual2: harga_jual2,
                            harga_jual_member2: harga_jual_member2,
                            pecahan2: pecahan2,
                            satuan3: varsatuan3,
                            harga_beli3: harga_beli3,
                            harga_jual3: harga_jual3,
                            harga_jual_member3: harga_jual_member3,
                            pecahan3: pecahan3,
                            satuan4: varsatuan4,
                            harga_beli4: harga_beli4,
                            harga_jual4: harga_jual4,
                            harga_jual_member4: harga_jual_member4,
                            pecahan4: pecahan4,
                            status: status,
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_produk();
                        tampil_produk(1);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/produk/simpan_produk.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_cabang: localStorage.getItem("id_cabang"),
                            id_produk: id_produk,
                            id_jenis: jenis.id_jenis,
                            id_supplier: supplier.id_supplier,
                            nama: nama,
                            kode: kode,
                            satuan1: varsatuan1,
                            harga_beli1: harga_beli1,
                            harga_jual1: harga_jual1,
                            harga_jual_member1: harga_jual_member1,
                            satuan2: varsatuan2,
                            harga_beli2: harga_beli2,
                            harga_jual2: harga_jual2,
                            harga_jual_member2: harga_jual_member2,
                            pecahan2: pecahan2,
                            satuan3: varsatuan3,
                            harga_beli3: harga_beli3,
                            harga_jual3: harga_jual3,
                            harga_jual_member3: harga_jual_member3,
                            pecahan3: pecahan3,
                            satuan4: varsatuan4,
                            harga_beli4: harga_beli4,
                            harga_jual4: harga_jual4,
                            harga_jual_member4: harga_jual_member4,
                            pecahan4: pecahan4,
                            status: status,
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_produk(1);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
    }

    const ubah_jumlah_cetak = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setJumlahCetak(event.target.value);
    }

    const perbaiki_jumlah_cetak = (event) => {
        if (event.target.value.trim() === "") {
            setJumlahCetak(1);
        }
    }

    const cetak = () => {
        if (kode === "") {
            setJenisNotif("warning");
            setIsiNotif("Barcode tidak boleh kosong");
            setTampilNotif(true);
            return;
        }

        window.open(props.aplikasi + '/produk/cetak_barcode.php?id_cabang=' + localStorage.getItem("id_cabang") + '&kode=' + kode + '&jumlah=' + jumlah_cetak);
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_produk();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <Typography variant="body1">Total Aset : Rp. {format_rupiah(parseFloat(total))}</Typography>

                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama produk atau scan barcode"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        value={pencarian}
                        onChange={(event) => setPencarian(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                cari();
                            }
                        }}
                    />
                </Card>

                <Grid
                    container item xs={12}
                    justifyContent="center"
                    sx={{
                        marginTop: "10px",
                        marginBottom: "10px"
                    }}
                >
                    <Stack spacing={2}>
                        <Pagination
                            color="primary"
                            count={jumlah_halaman}
                            page={halaman}
                            onChange={(event, nomor) => {
                                pindah_halaman(nomor);
                            }}
                        />
                    </Stack>
                </Grid>

                <Grid container spacing={1} alignItems="stretch" sx={{ padding: "10px" }}>
                    {data_produk
                        .map((tampung_produk, index) => {
                            return (
                                <Grid
                                    item xs={12} sm={6} md={4} lg={3}
                                    key={index}
                                    onClick={() => {
                                        bersih_input_produk();
                                        setIdSupplier(tampung_produk.id_supplier);
                                        setIdProduk(tampung_produk.id_produk);

                                        let jenis_produk = tampung_produk.id_jenis;
                                        let awal = 1;
                                        let akhir = data_jenis.length;
                                        while (awal <= akhir) {
                                            if (jenis_produk === data_jenis[awal - 1]["id_jenis"]) {
                                                setJenis(data_jenis[awal - 1]);
                                                break;
                                            }
                                            awal++;
                                        }

                                        let supplier = tampung_produk.id_supplier;
                                        awal = 1;
                                        akhir = data_supplier.length;
                                        while (awal <= akhir) {
                                            if (supplier === data_supplier[awal - 1]["id_supplier"]) {
                                                setSupplier(data_supplier[awal - 1]);
                                                break;
                                            }
                                            awal++;
                                        }

                                        let satuan1 = tampung_produk.id_satuan1;
                                        awal = 1;
                                        akhir = data_satuan.length;
                                        while (awal <= akhir) {
                                            if (satuan1 === data_satuan[awal - 1]["id_satuan"]) {
                                                setSatuan1(data_satuan[awal - 1]);
                                                break;
                                            }
                                            awal++;
                                        }

                                        let satuan2 = tampung_produk.id_satuan2;
                                        awal = 1;
                                        akhir = data_satuan.length;
                                        while (awal <= akhir) {
                                            if (satuan2 === data_satuan[awal - 1]["id_satuan"]) {
                                                setSatuan2(data_satuan[awal - 1]);
                                                break;
                                            }
                                            awal++;
                                        }

                                        let satuan3 = tampung_produk.id_satuan3;
                                        awal = 1;
                                        akhir = data_satuan.length;
                                        while (awal <= akhir) {
                                            if (satuan3 === data_satuan[awal - 1]["id_satuan"]) {
                                                setSatuan3(data_satuan[awal - 1]);
                                                break;
                                            }
                                            awal++;
                                        }

                                        let satuan4 = tampung_produk.id_satuan4;
                                        awal = 1;
                                        akhir = data_satuan.length;
                                        while (awal <= akhir) {
                                            if (satuan4 === data_satuan[awal - 1]["id_satuan"]) {
                                                setSatuan4(data_satuan[awal - 1]);
                                                break;
                                            }
                                            awal++;
                                        }

                                        setNama(tampung_produk.nama);
                                        setKode(tampung_produk.kode);
                                        setHargaBeli1(tampung_produk.harga_beli1);
                                        setHargaJual1(tampung_produk.harga_jual1);
                                        setHargaJualMember1(tampung_produk.harga_jual_member1);
                                        setHargaBeli2(tampung_produk.harga_beli2);
                                        setHargaJual2(tampung_produk.harga_jual2);
                                        setHargaJualMember2(tampung_produk.harga_jual_member2);
                                        setHargaBeli3(tampung_produk.harga_beli3);
                                        setHargaJual3(tampung_produk.harga_jual3);
                                        setHargaJualMember3(tampung_produk.harga_jual_member3);
                                        setHargaBeli4(tampung_produk.harga_beli4);
                                        setHargaJual4(tampung_produk.harga_jual4);
                                        setHargaJualMember4(tampung_produk.harga_jual_member4);
                                        setPecahan2(tampung_produk.pecahan2);
                                        setPecahan3(tampung_produk.pecahan3);
                                        setPecahan4(tampung_produk.pecahan4);

                                        if (tampung_produk.status === "1") {
                                            setStatus(true);
                                            setLabelStatus("Aktif");
                                        }
                                        else {
                                            setStatus(false);
                                            setLabelStatus("Nonaktif");
                                        }
                                        setTampilDialog(true);
                                    }}
                                >
                                    <Card
                                        sx={
                                            tampung_produk.status === "0" ?
                                                {
                                                    backgroundColor: "#FA7070",
                                                    color: "white"
                                                }
                                                :
                                                {}
                                        }
                                    >
                                        <List>
                                            <ListItem button>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body1" noWrap>{tampung_produk.nama}</Typography>
                                                    }
                                                // secondary={
                                                //     <Typography variant="subtitle2" noWrap>
                                                //         {
                                                //             "Rp. " + format_rupiah(parseFloat(tampung_produk.harga_beli1)) +
                                                //             " x " +
                                                //             tampung_produk.jumlah1 + " " + tampung_produk.satuan1 +
                                                //             " = Rp. " +
                                                //             format_rupiah(tampung_produk.harga_beli1 * tampung_produk.jumlah)
                                                //         }
                                                //     </Typography>
                                                // }
                                                >
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>

                <Grid
                    container item xs={12}
                    justifyContent="center"
                    sx={{
                        marginBottom: "10px"
                    }}
                >
                    <Stack spacing={2}>
                        <Pagination
                            color="primary"
                            count={jumlah_halaman}
                            page={halaman}
                            onChange={(event, nomor) => {
                                pindah_halaman(nomor);
                            }}
                        />
                    </Stack>
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Produk</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={2}>
                            <Grid container spacing={1} item xs={12}>
                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography component={"span"} variant="body2">
                                            ID Produk
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled
                                            value={tampil_id_produk()}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Supplier
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <Autocomplete
                                            id="supplier"
                                            options={data_supplier}
                                            getOptionLabel={(option) => tampil_id_supplier(option.id_supplier) + "-" + option.nama}
                                            value={supplier}
                                            onChange={(e, nilai) => {
                                                setSupplier(nilai);
                                            }}
                                            renderInput={(params) => <TextField {...params} key={params.id_supplier} variant="outlined" size="small" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Jenis Produk
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <Autocomplete
                                            id="jenis"
                                            options={data_jenis}
                                            getOptionLabel={(option) => option.nama}
                                            value={jenis}
                                            onChange={(e, nilai) => {
                                                setJenis(nilai);
                                            }}
                                            renderInput={(params) => <TextField {...params} key={params.id_jenis} variant="outlined" size="small" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Nama
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <TextField
                                            id="nama"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={nama}
                                            onChange={(event) => setNama(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Barcode
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <TextField
                                            id="kode"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            value={kode}
                                            onChange={(event) => setKode(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Status
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={status}
                                                    onChange={(event) => {
                                                        if (status === false) {
                                                            setStatus(true);
                                                            setLabelStatus("Aktif");
                                                        }
                                                        else {
                                                            setStatus(false);
                                                            setLabelStatus("Nonaktif");
                                                        }
                                                    }}
                                                />
                                            }

                                            label={label_status}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} item xs={12}>
                                <Grid item xs={12}>
                                    <Divider>Satuan 1</Divider>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Nama
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <Autocomplete
                                            id="satuan1"
                                            options={data_satuan}
                                            getOptionLabel={(option) => option.nama}
                                            value={satuan1}
                                            onChange={(e, nilai) => {
                                                ubah_satuan(nilai, 1);
                                            }}
                                            renderInput={(params) => <TextField {...params} key={params.id_satuan} variant="outlined" size="small" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Beli
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga_beli1"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_beli1}
                                                onChange={(event) => ubah_harga_beli(event, 1)}
                                                onBlur={(e) => perbaiki_harga_beli(e, 1)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Jual
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga1"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_jual1}
                                                onChange={(event) => ubah_harga_jual(event, 1)}
                                                onBlur={(e) => perbaiki_harga_jual(e, 1)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Jual Member
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga_jual_member1"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_jual_member1}
                                                onChange={(event) => ubah_harga_jual_member(event, 1)}
                                                onBlur={(e) => perbaiki_harga_jual_member(e, 1)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <br />
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider>Satuan 2</Divider>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Nama
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <Autocomplete
                                            id="satuan2"
                                            options={data_satuan}
                                            getOptionLabel={(option) => option.nama}
                                            value={satuan2}
                                            onChange={(e, nilai) => {
                                                ubah_satuan(nilai, 2);
                                            }}
                                            renderInput={(params) => <TextField {...params} key={params.id_satuan} variant="outlined" size="small" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Beli
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga_beli2"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_beli2}
                                                onChange={(event) => ubah_harga_beli(event, 2)}
                                                onBlur={(e) => perbaiki_harga_beli(e, 2)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Jual
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga2"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_jual2}
                                                onChange={(event) => ubah_harga_jual(event, 2)}
                                                onBlur={(e) => perbaiki_harga_jual(e, 2)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Jual Member
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga_jual_member2"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_jual_member2}
                                                onChange={(event) => ubah_harga_jual_member(event, 2)}
                                                onBlur={(e) => perbaiki_harga_jual_member(e, 2)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Pecahan
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                id="pecahan2"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={pecahan2}
                                                onChange={(event) => ubah_pecahan(event, 2)}
                                                onBlur={(e) => perbaiki_pecahan(e, 2)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant="body1" align="right" sx={{ color: "green" }}>
                                                Isi dari setiap satuan 1
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider>Satuan 3</Divider>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Nama
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <Autocomplete
                                            id="satuan3"
                                            options={data_satuan}
                                            getOptionLabel={(option) => option.nama}
                                            value={satuan3}
                                            onChange={(e, nilai) => {
                                                ubah_satuan(nilai, 3);
                                            }}
                                            renderInput={(params) => <TextField {...params} key={params.id_satuan} variant="outlined" size="small" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Beli
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga_beli3"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_beli3}
                                                onChange={(event) => ubah_harga_beli(event, 3)}
                                                onBlur={(e) => perbaiki_harga_beli(e, 3)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Jual
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga3"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_jual3}
                                                onChange={(event) => ubah_harga_jual(event, 3)}
                                                onBlur={(e) => perbaiki_harga_jual(e, 3)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Jual Member
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga_jual_member3"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_jual_member3}
                                                onChange={(event) => ubah_harga_jual_member(event, 3)}
                                                onBlur={(e) => perbaiki_harga_jual_member(e, 3)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Pecahan
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                id="pecahan3"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={pecahan3}
                                                onChange={(event) => ubah_pecahan(event, 3)}
                                                onBlur={(e) => perbaiki_pecahan(e, 3)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant="body1" align="right" sx={{ color: "green" }}>
                                                Isi dari setiap satuan 2
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <br />
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider>Satuan 4</Divider>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Nama
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <Autocomplete
                                            id="satuan4"
                                            options={data_satuan}
                                            getOptionLabel={(option) => option.nama}
                                            value={satuan4}
                                            onChange={(e, nilai) => {
                                                ubah_satuan(nilai, 4);
                                            }}
                                            renderInput={(params) => <TextField {...params} key={params.id_satuan} variant="outlined" size="small" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Beli
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga_beli4"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_beli4}
                                                onChange={(event) => ubah_harga_beli(event, 4)}
                                                onBlur={(e) => perbaiki_harga_beli(e, 4)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Jual
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga4"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_jual4}
                                                onChange={(event) => ubah_harga_jual(event, 4)}
                                                onBlur={(e) => perbaiki_harga_jual(e, 4)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Harga Jual Member
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="harga_jual_member4"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={harga_jual_member4}
                                                onChange={(event) => ubah_harga_jual_member(event, 4)}
                                                onBlur={(e) => perbaiki_harga_jual_member(e, 4)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Pecahan
                                        </Typography>
                                    </Grid>

                                    <Grid container item xs={12} sm={8} md={9}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                id="pecahan4"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={pecahan4}
                                                onChange={(event) => ubah_pecahan(event, 4)}
                                                onBlur={(e) => perbaiki_pecahan(e, 4)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant="body1" align="right" sx={{ color: "green" }}>
                                                Isi dari setiap satuan 3
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<QrCodeScannerIcon />}
                        sx={{ borderRadius: "5em", backgroundColor: "#8080FF" }}
                        loading={proses}
                        onClick={() => setTampilDialogCetak(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Barcode</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{id_produk === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_cetak}
                onClose={() => setTampilDialogCetak(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Jumlah Cetak
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Berapa baris barcode yang akan dicetak?
                    </DialogContentText>

                    <center>
                        <TextField
                            variant="outlined"
                            size="small"
                            inputProps={{
                                style: { textAlign: "center", width: "50px" }
                            }}
                            value={jumlah_cetak}
                            onChange={(event) => ubah_jumlah_cetak(event)}
                            onBlur={(e) => perbaiki_jumlah_cetak(e)}
                        />
                    </center>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<QrCodeScannerIcon />}
                        sx={{ borderRadius: "5em", backgroundColor: "#8080FF" }}
                        loading={proses}
                        onClick={() => cetak()}
                    >
                        <Typography variant="body2" noWrap={true}>Barcode</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialogCetak(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Produk);