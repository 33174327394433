// core
import React, { useState, useEffect, forwardRef } from 'react';

// 3rd
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

// aset
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

function Pelanggan(props, ref) {
    // state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [data_pelanggan, setDataPelanggan] = useState([]);
    const [pencarian, setPencarian] = useState("");
    const [id_pelanggan, setIdPelanggan] = useState("");
    const [nama, setNama] = useState("");
    const [alamat, setAlamat] = useState("");
    const [no_telpon, setNoTelpon] = useState("");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [jumlah_baris] = useState(40);
    const [jumlah_halaman, setJumlahHalaman] = useState(0);
    const [halaman, setHalaman] = useState(1);

    // fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pelanggan/tampil_pelanggan_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        status: "",
                        cari: "",
                        jumlah_baris: 40,
                        halaman: 1
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPelanggan(data.data);
                    setJumlahHalaman(Math.ceil(data.jumlah_data / 40));
                }
                else {
                    setDataPelanggan([]);
                    setJumlahHalaman(0);
                }
            })
            .catch(error => {
                setDataPelanggan([]);
                setJumlahHalaman(0);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_pelanggan = (halaman_baru) => {
        fetch(props.aplikasi + '/pelanggan/tampil_pelanggan_limit.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang"),
                        status: "",
                        cari: pencarian,
                        jumlah_baris: jumlah_baris,
                        halaman: halaman_baru
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPelanggan(data.data);
                    setJumlahHalaman(Math.ceil(data.jumlah_data / jumlah_baris));
                }
                else {
                    setDataPelanggan([]);
                    setJumlahHalaman(0);
                }
            })
            .catch(error => {
                setDataPelanggan([]);
                setJumlahHalaman(0);
            }
            );
    }

    const pindah_halaman = (halaman_baru) => {
        setHalaman(halaman_baru);
        tampil_pelanggan(halaman_baru);
    }

    const cari = () => {
        setHalaman(1);
        tampil_pelanggan(1);
    }

    const bersih_input_pelanggan = () => {
        setIdPelanggan("");
        setNama("");
        setAlamat("");
        setNoTelpon("");
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const simpan = () => {
        if (nama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama pelanggan wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (alamat.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("alamat wajib diisi");
            setTampilNotif(true);
            document.getElementById("alamat").focus();
            return;
        }

        setProses(true);
        if (id_pelanggan === "") {
            fetch(props.aplikasi + '/pelanggan/simpan_pelanggan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_pelanggan: "",
                            nama: nama,
                            alamat: alamat,
                            no_telpon: no_telpon,
                            status: status,
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_pelanggan();
                        tampil_pelanggan(1);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/pelanggan/simpan_pelanggan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_pelanggan: id_pelanggan,
                            nama: nama,
                            alamat: alamat,
                            no_telpon: no_telpon,
                            status: status,
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_pelanggan(1);
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_pelanggan();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama pelanggan (ketik kemudian tekan Enter)"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        value={pencarian}
                        onChange={(event) => setPencarian(event.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                cari();
                            }
                        }}
                    />
                </Card>

                <Grid
                    container item xs={12}
                    justifyContent="center"
                    sx={{
                        marginTop: "10px",
                        marginBottom: "10px"
                    }}
                >
                    <Stack spacing={2}>
                        <Pagination
                            color="primary"
                            count={jumlah_halaman}
                            page={halaman}
                            onChange={(event, nomor) => {
                                pindah_halaman(nomor);
                            }}
                        />
                    </Stack>
                </Grid>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_pelanggan
                        .map((tampung_produk, index) => {
                            return (
                                <Grid
                                    item xs={12} sm={6} md={4} lg={3}
                                    key={index}
                                    onClick={() => {
                                        bersih_input_pelanggan();
                                        setIdPelanggan(tampung_produk.id_pelanggan);
                                        setNama(tampung_produk.nama);
                                        setAlamat(tampung_produk.alamat);
                                        setNoTelpon(tampung_produk.no_telpon);
                                        if (tampung_produk.status === "1") {
                                            setStatus(true);
                                            setLabelStatus("Aktif");
                                        }
                                        else {
                                            setStatus(false);
                                            setLabelStatus("Nonaktif");
                                        }
                                        setTampilDialog(true);
                                    }}
                                >
                                    <Card
                                        sx={
                                            tampung_produk.status === "0" ?
                                                {
                                                    backgroundColor: "#FA7070",
                                                    color: "white"
                                                }
                                                :
                                                {}
                                        }
                                    >
                                        <List>
                                            <ListItem button>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body1" noWrap>{tampung_produk.nama + " (" + tampung_produk.no_telpon + ")"}</Typography>
                                                    }
                                                    secondary={
                                                        <Typography variant="body2" noWrap>{tampung_produk.alamat}</Typography>
                                                    }
                                                >
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>

                <Grid
                    container item xs={12}
                    justifyContent="center"
                    sx={{
                        marginBottom: "10px"
                    }}
                >
                    <Stack spacing={2}>
                        <Pagination
                            color="primary"
                            count={jumlah_halaman}
                            page={halaman}
                            onChange={(event, nomor) => {
                                pindah_halaman(nomor);
                            }}
                        />
                    </Stack>
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Pelanggan</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>                            

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Alamat
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="alamat"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={alamat}
                                        onChange={(event) => setAlamat(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        No. Telpon
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="no-telpon"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={no_telpon}
                                        onChange={(event) => setNoTelpon(event.target.value)}
                                    />
                                </Grid>
                            </Grid>                            

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{id_pelanggan === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>            
        </div>
    );
}

export default forwardRef(Pelanggan);