// core
import React, { useState, useEffect, forwardRef } from 'react';

// 3rd
import { withStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// aset
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(248,246,255,255)",
            borderColor: "rgba(248,246,255,255)"
        },
    },
}))(TableRow);

function Riwayat_Pembelian(props, ref) {
    // state
    const [tampil_blokir, setTampilBlokir] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [periode1, setPeriode1] = useState(new Date());
    const [periode2, setPeriode2] = useState(new Date());
    const [data_pembelian, setDataPembelian] = useState([]);
    const [data_metode_bayar, setDataMetodeBayar] = useState([]);
    const [metode_bayar, setMetodeBayar] = useState("");
    const [metode_bayar_sekarang, setMetodeBayarSekarang] = useState("");
    const [nama_metode_bayar_sekarang, setNamaMetodeBayarSekarang] = useState("");
    const [status_ubah, setStatusUbah] = useState("");
    const [id_pembelian, setIdPembelian] = useState("");
    const [no_faktur, setNoFaktur] = useState("");
    const [tgl_pembelian, setTglPembelian] = useState("");
    const [tgl_jatuh_tempo, setTglJatuhTempo] = useState("");
    const [id_supplier, setIdSupplier] = useState("");
    const [nama_supplier, setNamaSupplier] = useState("");
    const [bayar, setBayar] = useState("");
    const [kasir, setKasir] = useState("");
    const [waktu_transaksi, setWaktuTransaksi] = useState("");
    const [data_pembelian_detail, setDataPembelianDetail] = useState([]);
    const [total, setTotal] = useState(0);
    const [diskon, setDiskon] = useState(0);
    const [pembulatan, setPembulatan] = useState(0);
    const [grand_total, setGrandTotal] = useState(0);
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [tampil_dialog_ubah, setTampilDialogUbah] = useState(false);
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);
    const [tampil_dialog_pembatalan, setTampilDialogPembatalan] = useState(false);

    // fungsi    
    useEffect(() => {
        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        fetch(props.aplikasi + '/pembelian/tampil_riwayat.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        periode1: format_periode1,
                        periode2: format_periode2
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPembelian(data.data);
                }
                else {
                    setDataPembelian([]);
                }
            })
            .catch(error => {
                setDataPembelian([]);
            }
            );

        fetch(props.aplikasi + '/pembelian/tampil_metode_bayar.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_cabang: localStorage.getItem("id_cabang")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetodeBayar(data.data);
                }
                else {
                    setDataMetodeBayar([]);
                    setTampilBlokir(true);
                }
            })
            .catch(error => {
                setDataMetodeBayar([]);
                setTampilBlokir(true);
            }
            );
    }, [props.aplikasi, periode1, periode2]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const format_tanggal = (waktu, format) => {
        let tahun = waktu.substring(0, 4);
        let bulan = waktu.substring(5, 7);
        let tanggal = waktu.substring(8, 10);
        let jam = waktu.substring(11, 13);
        let menit = waktu.substring(14, 16);
        let detik = waktu.substring(17, 19);

        if (format === "short") {
            return tanggal + "-" + bulan + "-" + tahun;
        }
        else {
            return tanggal + "-" + bulan + "-" + tahun + " " + jam + ":" + menit + ":" + detik;
        }
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_pembelian_detail = (id_pembelian) => {
        fetch(props.aplikasi + '/pembelian/tampil_pembelian_detail.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembelian: id_pembelian
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataPembelianDetail(data.data);
                }
                else {
                    setDataPembelianDetail([]);
                }
            })
            .catch(error => {
                setDataPembelianDetail([]);
            }
            );
    }

    const tampil_subtotal = (varjumlah1, varjumlah2, varjumlah3, varjumlah4, varharga_beli1, varharga_beli2, varharga_beli3, varharga_beli4) => {
        let varsubtotal = 0;

        varsubtotal = (varjumlah1 * varharga_beli1) + (varjumlah2 * varharga_beli2) + (varjumlah3 * varharga_beli3) + (varjumlah4 * varharga_beli4);

        return varsubtotal;
    }

    const switch_status_ubah = (param) => {
        switch (param) {
            case "N":
                return "Tidak Ada Permintaan";
            case "R":
                return "Menunggu Diterima";
            case "A":
                return "Diterima";
            case "D":
                return "Ditolak";
            default:
                break;
        }
    }

    const switch_warna_status_ubah = (param) => {
        switch (param) {
            case "N":
                return "red";
            case "R":
                return "gold";
            case "A":
                return "green";
            case "D":
                return "brown";
            default:
                break;
        }
    }

    const simpan = () => {
        if (metode_bayar.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Metode bayar wajib diisi");
            setTampilNotif(true);
            document.getElementById("metode_bayar").focus();
            return;
        }

        fetch(props.aplikasi + '/pembelian/simpan_ubah_metode_bayar.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembelian: id_pembelian,
                        metode_sekarang: metode_bayar_sekarang,
                        metode_baru: metode_bayar,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setStatusUbah("R");
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }    

    const hapus = () => {
        fetch(props.aplikasi + '/pembelian/hapus_permintaan_ubah.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembelian: id_pembelian
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setTampilDialogUbah(false);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }    

    const simpan_pembatalan = () => {
        fetch(props.aplikasi + '/pembelian/simpan_pembatalan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pembelian: id_pembelian,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={tampil_blokir}
            >
                <Typography variant="h6" color="white">
                    Halaman Gagal Dimuat Dengan Sempurna. Silakan Reload Atau Tekan F5
                </Typography>
            </Backdrop>

            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    <Grid item xs={12}>
                        <Card
                            sx={{ padding: "10px" }}
                        >
                            <Grid container spacing={1}>
                                <Grid container item>
                                    <Grid item xs={12} sm={4} md={3} >
                                        <Typography variant="body2">
                                            Tanggal Transaksi
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9} sx={{ display: "flex" }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                inputFormat="dd-MM-yyyy"
                                                value={periode1}
                                                onChange={(newValue) => {
                                                    setPeriode1(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>

                                        <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                            s/d
                                        </Typography>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                inputFormat="dd-MM-yyyy"
                                                value={periode2}
                                                onChange={(newValue) => {
                                                    setPeriode2(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {data_pembelian.map((data_pembelian, index) => {
                                return (
                                    <Grid
                                        key={index}
                                        item xs={6} sm={4} md={3} lg={2}
                                        onClick={() => {
                                            setIdPembelian(data_pembelian.id_pembelian);
                                            setNoFaktur(data_pembelian.no_faktur);
                                            setTglPembelian(data_pembelian.tanggal_pembelian);
                                            setTglJatuhTempo(data_pembelian.tanggal_jatuh_tempo);
                                            setIdSupplier(data_pembelian.id_supplier);
                                            setNamaSupplier(data_pembelian.nama_supplier);
                                            if (String(data_pembelian.metode_bayar2) === "") {
                                                setBayar(data_pembelian.metode_bayar);
                                            }
                                            else {
                                                setBayar(data_pembelian.metode_bayar + " & " + data_pembelian.metode_bayar2);
                                            }
                                            setKasir(data_pembelian.kasir);
                                            setWaktuTransaksi(data_pembelian.waktu_transaksi);
                                            setTotal(data_pembelian.subtotal);
                                            setDiskon(data_pembelian.diskon);
                                            setPembulatan(data_pembelian.pembulatan);
                                            setGrandTotal(data_pembelian.grand_total);
                                            tampil_pembelian_detail(data_pembelian.id_pembelian);
                                            setTampilDialog(true);
                                        }}
                                    >
                                        <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                            <List>
                                                <ListItem button>
                                                    <ListItemText
                                                        primary={<Typography noWrap variant="subtitle1">{data_pembelian.nama_supplier}</Typography>}
                                                        secondary={
                                                            <div>
                                                                <Typography noWrap variant="body2" color="white">
                                                                    Rp. {format_rupiah(parseFloat(data_pembelian.grand_total))}
                                                                </Typography>
                                                                <Typography noWrap variant="body2" color="white">
                                                                    {format_tanggal(data_pembelian.waktu_transaksi, "long")}
                                                                </Typography>
                                                            </div>
                                                        }
                                                    >
                                                    </ListItemText>
                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
                maxWidth="lg"
            >
                <DialogTitle>Detail Pembelian</DialogTitle>
                <DialogContent>
                    <Box sx={{ padding: "10px" }}>
                        <Card sx={{ padding: "10px" }}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                No. Bukti
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {id_pembelian}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                No. Faktur
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {no_faktur}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Tgl. Pembelian
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {format_tanggal(tgl_pembelian, "short")}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Supplier
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {nama_supplier}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Kasir
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {kasir}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Metode Bayar
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {bayar}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Tgl. Jatuh Tempo
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {format_tanggal(tgl_jatuh_tempo, "short")}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body2">
                                                Waktu Transaksi
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                :
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography variant="body2">
                                                {format_tanggal(waktu_transaksi, "long")}
                                            </Typography>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>

                        <Card sx={{ marginTop: "10px", padding: "10px" }}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader size="small" aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">Nama</StyledTableCell>
                                            <StyledTableCell align="center">Harga</StyledTableCell>
                                            <StyledTableCell align="center">Jumlah</StyledTableCell>
                                            <StyledTableCell align="center">Subtotal</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data_pembelian_detail.map((data_pembelian_detail) => {
                                            return (
                                                <StyledTableRow key={data_pembelian_detail.id_produk}>
                                                    <TableCell size="small"><Typography variant="body2" noWrap={true}>{data_pembelian_detail.nama}</Typography></TableCell>
                                                    <TableCell size="small" align="right">
                                                        {data_pembelian_detail.jumlah1 > 0 ?
                                                            <Typography variant="body2" noWrap={true}>
                                                                {"Rp. " + format_rupiah(parseFloat(data_pembelian_detail.harga_beli1))}
                                                            </Typography>
                                                            :
                                                            <></>
                                                        }

                                                        {data_pembelian_detail.jumlah2 > 0 ?
                                                            <Typography variant="body2" noWrap={true}>
                                                                {"Rp. " + format_rupiah(parseFloat(data_pembelian_detail.harga_beli2))}
                                                            </Typography>
                                                            :
                                                            <></>
                                                        }

                                                        {data_pembelian_detail.jumlah3 > 0 ?
                                                            <Typography variant="body2" noWrap={true}>
                                                                {"Rp. " + format_rupiah(parseFloat(data_pembelian_detail.harga_beli3))}
                                                            </Typography>
                                                            :
                                                            <></>
                                                        }

                                                        {data_pembelian_detail.jumlah4 > 0 ?
                                                            <Typography variant="body2" noWrap={true}>
                                                                {"Rp. " + format_rupiah(parseFloat(data_pembelian_detail.harga_beli4))}
                                                            </Typography>
                                                            :
                                                            <></>
                                                        }
                                                    </TableCell>
                                                    <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>
                                                        {data_pembelian_detail.jumlah1 > 0 ?
                                                            <Typography variant="body2" noWrap={true}>
                                                                {format_rupiah(parseFloat(data_pembelian_detail.jumlah1)) + " " + data_pembelian_detail.satuan1}
                                                            </Typography>
                                                            :
                                                            <></>
                                                        }

                                                        {data_pembelian_detail.jumlah2 > 0 ?
                                                            <Typography variant="body2" noWrap={true}>
                                                                {format_rupiah(parseFloat(data_pembelian_detail.jumlah2)) + " " + data_pembelian_detail.satuan2}
                                                            </Typography>
                                                            :
                                                            <></>
                                                        }

                                                        {data_pembelian_detail.jumlah3 > 0 ?
                                                            <Typography variant="body2" noWrap={true}>
                                                                {format_rupiah(parseFloat(data_pembelian_detail.jumlah3)) + " " + data_pembelian_detail.satuan3}
                                                            </Typography>
                                                            :
                                                            <></>
                                                        }

                                                        {data_pembelian_detail.jumlah4 > 0 ?
                                                            <Typography variant="body2" noWrap={true}>
                                                                {format_rupiah(parseFloat(data_pembelian_detail.jumlah4)) + " " + data_pembelian_detail.satuan4}
                                                            </Typography>
                                                            :
                                                            <></>
                                                        }
                                                    </Typography></TableCell>
                                                    <TableCell size="small" align="right">
                                                        <Typography variant="body2" noWrap={true}>                                                            
                                                            Rp. {format_rupiah(tampil_subtotal(
                                                                data_pembelian_detail.jumlah1,
                                                                data_pembelian_detail.jumlah2,
                                                                data_pembelian_detail.jumlah3,
                                                                data_pembelian_detail.jumlah4,
                                                                data_pembelian_detail.harga_beli1,
                                                                data_pembelian_detail.harga_beli2,
                                                                data_pembelian_detail.harga_beli3,
                                                                data_pembelian_detail.harga_beli4
                                                            ))}
                                                        </Typography>
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        })}

                                        <TableRow>
                                            <TableCell align="right" colSpan={3}>
                                                <Typography variant="h6">
                                                    Total
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "green" }}>
                                                {"Rp. " + format_rupiah(parseFloat(total))}
                                            </Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" colSpan={3}>
                                                <Typography variant="h6">
                                                    Diskon
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "green" }}>
                                                {"Rp. " + format_rupiah(parseFloat(diskon))}
                                            </Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" colSpan={3}>
                                                <Typography variant="h6">
                                                    Pembulatan
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "green" }}>
                                                {"Rp. " + format_rupiah(parseFloat(pembulatan))}
                                            </Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right" colSpan={3}>
                                                <Typography variant="h6">
                                                    Grand Total
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right"><Typography variant="h4" noWrap={true} sx={{ color: "red" }}>
                                                {"Rp. " + format_rupiah(parseFloat(grand_total))}
                                            </Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={{ xs: 1 }} direction="row" justifyContent="flex-end" useFlexGap flexWrap="wrap">
                        {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<PrintIcon />}
                            sx={{ borderRadius: "5em", backgroundColor: "#8080FF" }}
                            onClick={() =>
                                window.open(props.aplikasi + '/pembelian/cetak_struk.php?id_pembelian=' + id_pembelian)
                            }
                        >
                            <Typography variant="body2" noWrap={true}>Cetak</Typography>
                        </Button> */}

                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            sx={{ borderRadius: "5em", backgroundColor: "tomato" }}
                            onClick={() => {
                                fetch(props.aplikasi + '/pembelian/tampil_ubah_metode_bayar.php',
                                    {
                                        method: 'POST',
                                        body: JSON.stringify
                                            ({
                                                id_pembelian: id_pembelian
                                            }),
                                        headers:
                                        {
                                            "Content-type": "application/json; charset=UTF-8"
                                        }
                                    })
                                    .then(response => response.json())
                                    .then(data => {
                                        if (data.kode === 1) {
                                            setMetodeBayarSekarang(data.data[0].id_metode_bayar);
                                            setNamaMetodeBayarSekarang(data.data[0].metode_bayar);
                                            setMetodeBayar(data.data[0].metode_baru);
                                            setStatusUbah(data.data[0].status_ubah);
                                        }
                                        else {
                                            setMetodeBayarSekarang("");
                                            setNamaMetodeBayarSekarang("");
                                            setMetodeBayar("");
                                            setStatusUbah("N");
                                        }
                                    })
                                    .catch(error => {
                                        setMetodeBayarSekarang("");
                                        setNamaMetodeBayarSekarang("");
                                        setMetodeBayar("");
                                        setStatusUbah("N");
                                    }
                                    );

                                setTampilDialogUbah(true);
                            }}
                        >
                            <Typography variant="body2" noWrap={true}>Ubah Cara Bayar</Typography>
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<DeleteIcon />}
                            sx={{ borderRadius: "5em", backgroundColor: "red" }}
                            onClick={() => {
                                setTampilDialogPembatalan(true);
                            }}
                        >
                            <Typography variant="body2" noWrap={true}>Ajukan Pembatalan</Typography>
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<CloseOutlined />}
                            sx={{ borderRadius: "5em", backgroundColor: "#555555" }}
                            onClick={() => setTampilDialog(false)}
                        >
                            <Typography variant="body2" noWrap={true}>Tutup</Typography>
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_ubah}
                onClose={() => setTampilDialogUbah(false)}
            >
                <DialogTitle>Permintaan Pengubahan Metode Bayar</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5} sm={4} md={4}>
                                    <Typography variant="body2">
                                        Metode Saat Ini
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={8}>
                                    <Typography variant="body2">
                                        {nama_metode_bayar_sekarang}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={4}>
                                    <Typography variant="body2">
                                        Menjadi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={8}>
                                    <FormControl size="small" fullWidth>
                                        <Select
                                            id="metode_bayar"
                                            variant="outlined"
                                            fullWidth
                                            value={metode_bayar}
                                            onChange={(event) => {
                                                setMetodeBayar(event.target.value);
                                            }}
                                        >
                                            {data_metode_bayar.map((data_metode_bayar) => {
                                                return (
                                                    <MenuItem key={data_metode_bayar.id_metode} value={data_metode_bayar.id_metode}>{data_metode_bayar.nama}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5} sm={4} md={4}>
                                    <Typography variant="body2">
                                        Status Permintaan
                                    </Typography>
                                </Grid>

                                <Grid item xs={7} sm={8} md={8}>
                                    <Typography variant="body2"
                                        sx={{
                                            color: switch_warna_status_ubah(status_ubah)
                                        }}
                                    >
                                        {switch_status_ubah(status_ubah)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>Simpan</Typography>
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        onClick={() => setTampilDialogHapus(true)}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialogUbah(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Permintaan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTampilDialogHapus(false)} autoFocus>Tidak</Button>
                    <Button
                        onClick={() => {
                            hapus();
                            setTampilDialogHapus(false);
                        }}
                    >Ya</Button>
                </DialogActions>
            </Dialog>            

            <Dialog
                open={tampil_dialog_pembatalan}
                onClose={() => setTampilDialogPembatalan(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Pembatalan Transaksi
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin membatalkan transaksi ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTampilDialogPembatalan(false)} autoFocus>Tidak</Button>
                    <Button
                        onClick={() => {
                            simpan_pembatalan();
                            setTampilDialogPembatalan(false);
                        }}
                    >Ya</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Riwayat_Pembelian);