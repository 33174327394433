import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

//gambar
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

function Beranda(props, ref) {
    //state    
    const [pendapatan_hari, setPendapatanHari] = useState(0);
    const [pendapatan_bulan, setPendapatanBulan] = useState(0);
    const [data_produk_terlaris_hari, setDataProdukTerlarisHari] = useState([]);
    const [data_produk_terlaris_bulan, setDataProdukTerlarisBulan] = useState([]);
    const [data_habis, setDataHabis] = useState([]);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/admin/tampil_beranda.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setPendapatanHari(data.data[0].pendapatan_hari);
                    setPendapatanBulan(data.data[0].pendapatan_bulan);
                    setDataProdukTerlarisHari(data.data[0].produk_terlaris_hari);
                    setDataProdukTerlarisBulan(data.data[0].produk_terlaris_bulan);
                    setDataHabis(data.data[0].habis);
                }
                else {
                    setPendapatanHari(0);
                    setPendapatanBulan(0);
                    setDataProdukTerlarisHari([]);
                    setDataProdukTerlarisBulan([]);
                    setDataHabis([]);
                }
            })
            .catch(error => {
                setPendapatanHari(0);
                setPendapatanBulan(0);
                setDataProdukTerlarisHari([]);
                setDataProdukTerlarisBulan([]);
                setDataHabis([]);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/admin/tampil_beranda.php',
                {
                    method: 'GET',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setPendapatanHari(data.data[0].pendapatan_hari);
                        setPendapatanBulan(data.data[0].pendapatan_bulan);
                        setDataProdukTerlarisHari(data.data[0].produk_terlaris_hari);
                        setDataProdukTerlarisBulan(data.data[0].produk_terlaris_bulan);
                        setDataHabis(data.data[0].habis);
                    }
                    else {
                        setPendapatanHari(0);
                        setPendapatanBulan(0);
                        setDataProdukTerlarisHari([]);
                        setDataProdukTerlarisBulan([]);
                        setDataHabis([]);
                    }
                })
                .catch(error => {
                    setPendapatanHari(0);
                    setPendapatanBulan(0);
                    setDataProdukTerlarisHari([]);
                    setDataProdukTerlarisBulan([]);
                    setDataHabis([]);
                }
                );
        }, 10000)

        return () => {
            clearInterval(auto_refresh);
        };
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    return (
        <div>
            <Grid container spacing={1} sx={{ padding: "10px" }}>
                <Grid
                    item xs={12} sm={6} md={4}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "#51ba16" }}
                    >
                        <Grid container alignItems="center">
                            <Grid
                                item xs={11}
                            >
                                <Typography variant="subtitle2"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Pendapatan
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={1}
                            >
                                <AttachMoneyIcon
                                    sx={{
                                        color: "white",
                                        padding: "0"
                                    }}
                                />
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Hari Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseFloat(pendapatan_hari))}
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Bulan Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseFloat(pendapatan_bulan))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item xs={12} sm={6} md={4}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "white" }}
                    >
                        <Typography variant="h6">
                            Produk Terlaris
                        </Typography>
                        <Divider />
                        <br />

                        <Grid
                            container
                        >
                            <Grid
                                item xs={5}
                            >
                                <Typography variant="h6"
                                    sx={{
                                        padding: "5px",
                                        backgroundColor: "#baa8e6",
                                        color: "white"
                                    }}
                                >
                                    Hari Ini
                                </Typography>
                                <Grid container item spacing={1}>
                                    {data_produk_terlaris_hari.map((data_terlaris_makanan, index) => {
                                        return (
                                            <Grid key={index} container item xs={12}>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {data_terlaris_makanan.nama}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                        {data_terlaris_makanan.jumlah}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>

                            <Grid
                                container item xs={1} justifyContent="center"
                            >
                                <Divider orientation='vertical' variant='middle' />
                            </Grid>

                            <Grid
                                item xs={6}
                            >
                                <Typography variant="h6"
                                    sx={{
                                        padding: "5px",
                                        backgroundColor: "#baa8e6",
                                        color: "white"
                                    }}
                                >
                                    Bulan Ini
                                </Typography>
                                <Grid container item spacing={1}>
                                    {data_produk_terlaris_bulan.map((data_terlaris_makanan, index) => {
                                        return (
                                            <Grid key={index} container item xs={12}>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {data_terlaris_makanan.nama}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                        {data_terlaris_makanan.jumlah}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item xs={12} sm={6} md={4}
                >
                    <Grid
                        container spacing={1}
                    >
                        <Grid
                            item xs={12}
                        >
                            <Card
                                sx={{ padding: "10px", backgroundColor: "white" }}
                            >
                                <Typography variant="h6">
                                    Produk Habis
                                </Typography>
                                <Divider />
                                <br />
                                <Grid container item spacing={1}>
                                    {data_habis.map((data_habis, index) => {
                                        return (
                                            <Grid key={index} item xs={12}>
                                                <Typography variant="body2">
                                                    {data_habis.nama}
                                                </Typography>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default forwardRef(Beranda);